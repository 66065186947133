import React, { useEffect, useState } from 'react'
import { Box, Typography, useTheme, Button, Modal, Select, MenuItem } from '@mui/material'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import FR from '@fullcalendar/core/locales/fr'
import BoardLoading from './BoardLoading'
import SheduledStore from '../../services/store/Sheduled'
import Post from './Post'
import SocialPost from './SocialPost'
import YoutubeStore from '../../services/store/Youtube'
import FacebookStore from '../../services/store/Facebook'
import InstagramStore from '../../services/store/Instagram'
import PinterestStore from '../../services/store/Pinterest'
// import TwitterStore from '../../services/store/Twitter'

interface IProps {
    account: any
    onSelected: any
    pubInfo: string | undefined
}

interface IData {
    id: string
    title: string
    start: string
    allDay: boolean
}

export default ({ account, onSelected, pubInfo }: IProps) => {
    const Theme = useTheme()
    const [reload, setReload] = useState(false)
    const [data, setData] = useState<Array<IData>>([])
    const [selected, setSelected] = useState<any>(null)
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const [pubData, setPubData] = useState<any>(null)
    const [filter, setFilter] = useState<string>('programmé')
    const [all, setAll] = useState(false)

    useEffect(() => {
        if (pubInfo) {
            const decoded = JSON.parse(atob(pubInfo))
            if (decoded && decoded.type) {
                setPubData(decoded)
                setOpen(true)
            }
        }
    }, [pubInfo])

    useEffect(() => {
        setAll(false)
        const API = new SheduledStore()
        let isSubscribed = true
        let timeout: any
        setReload(true)
        ;(async () => {
            if (isSubscribed) {
                if (account) {
                    if (filter === 'programmé') {
                        const response = await API.getSheduled(account.pageId)
                        if (!response.canceled) {
                            setData(response)
                        }
                    } else {
                        await onLoadPublications()
                    }
                } else {
                    setAll(true)
                    const response = await API.getAllSheduled()
                    if (!response.canceled) {
                        setData(response)
                    }
                }
                timeout = setTimeout(() => {
                    setReload(false)
                }, 1000)
            }
        })()

        return () => {
            API.Cancel()
            isSubscribed = false
            clearTimeout(timeout)
        }
    }, [account, filter])

    const onLoadPublications = async () => {
        if (account) {
            const accountInfo = {
                id: account.id,
                type: account.type,
                pageId: account.pageId
            }
            const encodedAccess = btoa(JSON.stringify(accountInfo))
            if (account.type === 'youtube') {
                const API = new YoutubeStore()
                const response = await API.getMyPosts(encodedAccess)
                if (!response.canceled) setData(response)
            }
            if (account.type === 'twitter') {
                /** const API = new TwitterStore()
            const response = await API.getMyPosts(access) */
                setData([])
            }
            if (account.type === 'facebook') {
                const API = new FacebookStore()
                const response = await API.getMyPosts(encodedAccess)
                if (!response.canceled) setData(response)
            }
            if (account.type === 'instagram') {
                const API = new InstagramStore()
                const response = await API.getMyPosts(encodedAccess)
                if (!response.canceled) setData(response)
            }

            if (account.type === 'pinterest') {
                const API = new PinterestStore()
                const response = await API.getPosts(encodedAccess)
                if (!response.canceled) setData(response)
            }
        }
    }

    const onSelect = (info: any) => {
        if (filter === 'publié') {
            if (account && account.type) {
                const accountInfo = {
                    id: account.id,
                    type: account.type,
                    pageId: account.pageId
                }
                const encodedAccess = btoa(JSON.stringify(accountInfo))
                const dataInfo = {
                    id: info.event.id,
                    type: account.type,
                    access: encodedAccess
                }
                setPubData(dataInfo)
                setOpen(true)
            }
        } else {
            setSelected(info.event ?? null)
            setShow(true)
        }
    }

    const onClose = () => {
        setShow(false)
    }

    const onLoadAll = async () => {
        setAll(true)
        onSelected(null)
        const API = new SheduledStore()
        const response = await API.getAllSheduled()
        if (!response.canceled) {
            setData(response)
            /* setReload(true)
            const timeout = setTimeout(() => {
                setReload(false)
            }, 10) */
        }
    }

    if (reload) return <BoardLoading />

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                minHeight: '100%',
                minWidth: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
                backgroundColor: Theme.palette.primary.main,
                borderRadius: '10px'
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    minWidth: '98%',
                    maxWidth: '98%',
                    minHeight: '7%',
                    maxHeight: '7%',
                    paddingRight: '1%',
                    paddingLeft: '1%'
                }}>
                <Typography variant="h5">Tableau de board</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '100%',
                        maxHeight: '100%',
                        minWidth: '40%',
                        maxWidth: '40%',
                        alignItems: 'center',
                        justifyContent: all ? 'flex-end' : 'space-between'
                    }}>
                    {all ? null : (
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={filter}
                            size="small"
                            variant="standard"
                            onChange={(e) => setFilter(e.target.value)}
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem value="programmé">Programmé</MenuItem>
                            <MenuItem value="publié">Publié</MenuItem>
                        </Select>
                    )}
                    <Button onClick={onLoadAll} variant="contained" color="inherit">
                        Afficher tout
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    minWidth: '98%',
                    maxWidth: '98%',
                    minHeight: '92%',
                    maxHeight: '92%',
                    paddingRight: '1%',
                    paddingLeft: '1%'
                }}>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        start: 'prev,today,next',
                        center: 'title',
                        end: 'dayGridMonth,timeGridWeek'
                    }}
                    locale={FR}
                    titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                    aspectRatio={1}
                    height="100%"
                    events={data}
                    eventClick={(e) => onSelect(e)}
                />
            </Box>
            <Modal
                keepMounted
                open={open}
                onClose={() => {
                    setOpen(false)
                    setPubData(null)
                }}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: window.innerWidth,
                    minHeight: window.innerHeight
                }}>
                <SocialPost
                    data={pubData}
                    onClose={() => {
                        setOpen(false)
                        setPubData(null)
                    }}
                />
            </Modal>
            <Modal
                keepMounted
                open={show}
                onClose={onClose}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: window.innerWidth,
                    minHeight: window.innerHeight
                }}>
                <Post selected={selected} onClose={onClose} />
            </Modal>
        </Box>
    )
}
