import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

interface IProps {
    children: any
}

const PageTransition = ({ children }: IProps) => (
    <TransitionGroup>
        <CSSTransition
            key={window.location.pathname}
            timeout={500}
            classNames="page-transition"
            unmountOnExit>
            {children}
        </CSSTransition>
    </TransitionGroup>
)

export default PageTransition
