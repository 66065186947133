import React, { useState, useEffect } from 'react'
import { Tweet } from 'react-twitter-widgets'
import Youtube from 'react-youtube'
import InstagramStore from '../../services/store/Instagram'
import FacebookStore from '../../services/store/Facebook'
import PinterestStore from '../../services/store/Pinterest'
import Pin from './Pinterest'
import Insta from './Instagram'
import FB from './Facebook'

interface IProps {
    data: any
    onClose: any
}

export default ({ data, onClose }: IProps) => {
    const [info, setInfo] = useState<any>(null)

    useEffect(() => {
        let API: FacebookStore | InstagramStore | PinterestStore = new FacebookStore()
        let isSubscribed = true
        console.log(data)
        ;(async () => {
            if (data && data.type && isSubscribed) {
                if (data.type === 'twitter' || data.type === 'youtube') setInfo(data)
                if (data.type === 'facebook') {
                    const response = await API.getPost(data.id, data.access)
                    setInfo(response)
                }
                if (data.type === 'pinterest') {
                    API = new PinterestStore()
                    const response = await API.getPost(data.id, data.access)
                    setInfo(response)
                }
                if (data.type === 'instagram') {
                    API = new InstagramStore()
                    const response = await API.getPost(data.id, data.access)
                    setInfo(response)
                }
            }
        })()

        return () => {
            API.Cancel()
            isSubscribed = false
        }
    }, [data])

    const handleClose = () => {
        setInfo(null)
        onClose()
    }

    const renderer = () => {
        if (data && data.type) {
            switch (data.type) {
                case 'youtube':
                    return <Youtube videoId={info.id} style={{ width: window.innerWidth / 2 }} />
                case 'twitter':
                    return <Tweet tweetId={info.id} options={{ width: `500` }} />
                case 'instagram':
                    return <Insta data={info} type={data.type} onClose={handleClose} />
                case 'facebook':
                    return <FB data={info} type={data.type} onClose={handleClose} />
                case 'pinterest':
                    return <Pin data={info} type={data.type} onClose={handleClose} />
                default:
                    return null
            }
        }
        return null
    }

    if (!data || !info) return null

    return renderer()
}
