/* eslint-disable no-restricted-syntax */
import axios from 'axios'
import Store from './Store'

export default class FacebookStore extends Store {
    private source = axios.CancelToken.source()

    public createPost = async (data: any, access: string) => {
        try {
            let type = 'text'
            const formdata = new FormData()

            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            if (data.link !== '') type = 'link'

            console.log(data.file[0].type)

            if (data.file.length > 0) {
                if (data.file[0].type.indexOf('image') !== -1) type = 'photo'
                if (data.file[0].type.indexOf('video') !== -1) type = 'video'
                formdata.append('file', data.file[0])
            }

            formdata.append('type', type)
            formdata.append('text', data.text)
            formdata.append('link', data.link)

            const response = (
                await axios.post(`${this.url}/facebook/post/create/${access}`, formdata, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getMyPosts = async (access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/facebook/posts/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getPost = async (id: string, access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/facebook/post/${id}/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getStats = async (access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/facebook/stats/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public Cancel = () => {
        this.source.cancel()
    }
}
