import React from 'react'
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme
} from '@mui/material'
import {
    Home,
    Person,
    People,
    FormatListBulleted,
    ArtTrack,
    BarChart,
    Drafts,
    Sms,
    Description,
    BorderColor,
    AttachFile
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
// import Talk from '../../assets/images/postinTalk.jpg'

interface IProps {
    routeName: string
}

export default ({ routeName }: IProps) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const handleChange = (option: string) => {
        console.log(routeName)
        navigate(`/${option}`)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                minHeight: '100%',
                maxHeight: '100%',
                minWidth: '20%',
                maxWidth: '20%',
                padding: '10px'
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: '65%',
                    maxHeight: '65%',
                    minWidth: '97%',
                    maxWidth: '97%',
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: 2,
                    boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.5)',
                    padding: 0
                }}>
                <List
                    sx={{
                        minWidth: '100%',
                        minHeight: '100%'
                    }}>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleChange('board')}
                            sx={{
                                boxShadow:
                                    routeName === 'board'
                                        ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                                        : '0px 0px 0px rgba(0, 0, 0, 0)'
                            }}>
                            <ListItemIcon>
                                <Home color={routeName === 'board' ? 'info' : 'inherit'} />
                            </ListItemIcon>
                            <ListItemText primary="Tableau de bord" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleChange('publication')}
                            sx={{
                                boxShadow:
                                    routeName === 'publication'
                                        ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                                        : '0px 0px 0px rgba(0, 0, 0, 0)'
                            }}>
                            <ListItemIcon>
                                <BorderColor
                                    color={routeName === 'publication' ? 'info' : 'inherit'}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Créer une publication" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleChange('feed')}
                            sx={{
                                boxShadow:
                                    routeName === 'feed'
                                        ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                                        : '0px 0px 0px rgba(0, 0, 0, 0)'
                            }}>
                            <ListItemIcon>
                                <ArtTrack color={routeName === 'feed' ? 'info' : 'inherit'} />
                            </ListItemIcon>
                            <ListItemText primary="Fils d'actualités" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleChange('todolist')}
                            sx={{
                                boxShadow:
                                    routeName === 'todolist'
                                        ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                                        : '0px 0px 0px rgba(0, 0, 0, 0)'
                            }}>
                            <ListItemIcon>
                                <FormatListBulleted
                                    color={routeName === 'todolist' ? 'info' : 'inherit'}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Tâches à faire" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleChange('mailbox')}
                            sx={{
                                boxShadow:
                                    routeName === 'mailbox'
                                        ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                                        : '0px 0px 0px rgba(0, 0, 0, 0)'
                            }}>
                            <ListItemIcon>
                                <Drafts color={routeName === 'mailbox' ? 'info' : 'inherit'} />
                            </ListItemIcon>
                            <ListItemText primary="Boîte de réception" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleChange('chat')}
                            sx={{
                                boxShadow:
                                    routeName === 'chat'
                                        ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                                        : '0px 0px 0px rgba(0, 0, 0, 0)'
                            }}>
                            <ListItemIcon>
                                <Sms color={routeName === 'chat' ? 'info' : 'inherit'} />
                            </ListItemIcon>
                            <ListItemText primary="Conversation" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleChange('stats')}
                            sx={{
                                boxShadow:
                                    routeName === 'stats'
                                        ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                                        : '0px 0px 0px rgba(0, 0, 0, 0)'
                            }}>
                            <ListItemIcon>
                                <BarChart color={routeName === 'stats' ? 'info' : 'inherit'} />
                            </ListItemIcon>
                            <ListItemText primary="Statistiques" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleChange('notes')}
                            sx={{
                                boxShadow:
                                    routeName === 'notes'
                                        ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                                        : '0px 0px 0px rgba(0, 0, 0, 0)'
                            }}>
                            <ListItemIcon>
                                <AttachFile color={routeName === 'notes' ? 'info' : 'inherit'} />
                            </ListItemIcon>
                            <ListItemText primary="Memos et notes" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleChange('prospect')}
                            sx={{
                                boxShadow:
                                    routeName === 'prospect'
                                        ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                                        : '0px 0px 0px rgba(0, 0, 0, 0)'
                            }}>
                            <ListItemIcon>
                                <Description
                                    color={routeName === 'prospect' ? 'info' : 'inherit'}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Fiche prospects" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleChange('account')}
                            sx={{
                                boxShadow:
                                    routeName === 'account'
                                        ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                                        : '0px 0px 0px rgba(0, 0, 0, 0)'
                            }}>
                            <ListItemIcon>
                                <Person color={routeName === 'account' ? 'info' : 'inherit'} />
                            </ListItemIcon>
                            <ListItemText primary="Gérer les comptes" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleChange('team')}
                            sx={{
                                boxShadow:
                                    routeName === 'team'
                                        ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                                        : '0px 0px 0px rgba(0, 0, 0, 0)'
                            }}>
                            <ListItemIcon>
                                <People color={routeName === 'team' ? 'info' : 'inherit'} />
                            </ListItemIcon>
                            <ListItemText primary="Gérer les équipes" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Box>
    )
}
