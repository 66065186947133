import React from 'react'
import { Box, Skeleton } from '@mui/material'

const fake = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    }
]

const Item = () => (
    <Box sx={{ width: 345, marginRight: 0.5, my: 5 }}>
        <Skeleton variant="rectangular" width={345} height={250} />
        <Box sx={{ pt: 0.5 }}>
            <Skeleton />
            <Skeleton width="60%" />
        </Box>
    </Box>
)

export default () => (
    <Box
        sx={{
            minWidth: '100%',
            minHeight: '90%',
            maxHeight: '90%',
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            overflow: 'scroll'
        }}>
        {fake.map((el) => (
            <Item key={el.id} />
        ))}
    </Box>
)
