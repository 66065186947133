import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Youtube from './Youtube'
import Twitter from './Twitter'
import TimelineLoading from './TimelineLoading'

interface IProps {
    account: any
}

export default ({ account }: IProps) => {
    const [reload, setReload] = useState(false)

    useEffect(() => {
        setReload(true)
        const timeout = setTimeout(() => {
            setReload(false)
        }, 300)

        return () => {
            clearTimeout(timeout)
        }
    }, [account])

    const render = () => {
        switch (account.type) {
            case 'youtube':
                return <Youtube account={account} />
            case 'twitter':
                return <Twitter account={account} />
            default:
                return <TimelineLoading />
        }
    }

    if (reload) return <TimelineLoading />

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'row',
                justifyContent: 'space-around',
                minHeight: '100%',
                minWidth: '100%'
            }}>
            {render()}
        </Box>
    )
}
