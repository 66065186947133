import React, { useEffect, useState } from 'react'
import { Box, styled, alpha, InputBase, List } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import AccountStore from '../../services/store/Account'
import Item from './Item'
import LoadingItem from '../sous-components/LoadingItem'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '50px',
    backgroundColor: alpha(theme.palette.secondary.dark, 0.75),
    '&:hover': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.95)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    minWidth: '50%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto'
    }
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'secondary',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    }
}))

interface IProps {
    selected: any
    setSelected: any
}

const fake = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    },
    {
        id: 5
    },
    {
        id: 6
    },
    {
        id: 7
    },
    {
        id: 8
    }
]

export default ({ setSelected, selected }: IProps) => {
    const [data, setData] = useState<Array<any> | null>(null)
    const [search, setSearch] = useState('')
    const [list, setList] = useState<Array<any> | null>(null)

    useEffect(() => {
        const API = new AccountStore()
        console.log(selected)
        ;(async () => {
            const response = await API.getMyOwnAccounts()
            if (!response.canceled) {
                const accounts = Array.isArray(response) ? response : [response]
                setData(accounts)
                setList(accounts)
                if (accounts.length > 0) setSelected(accounts[0])
            }
        })()

        return () => {
            API.Cancel()
        }
    }, [])

    useEffect(() => {
        if (search !== '' && data) {
            const filtered = data.filter((el: any) => el.name.indexOf(search) !== -1)
            setList(filtered)
        }
    }, [search])

    return (
        <Box
            sx={{
                minWidth: '100%',
                minHeight: '100%',
                maxHeight: '100%',
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
            <Box
                sx={{
                    minWidth: '100%',
                    minHeight: '10%',
                    maxHeight: '10%',
                    maxWidth: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                        value={search}
                        onChange={(e) => setSearch(e.currentTarget.value)}
                    />
                </Search>
            </Box>
            <Box
                sx={{
                    minWidth: '100%',
                    minHeight: '90%',
                    maxHeight: '90%',
                    maxWidth: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    overflow: 'scroll'
                }}>
                {data ? (
                    <List
                        sx={{
                            minWidth: '100%',
                            minHeight: '100%',
                            maxHeight: '100%',
                            maxWidth: '100%'
                        }}>
                        {list
                            ? list.map((el: any) => (
                                  <Item
                                      key={el.pageId}
                                      data={el}
                                      setSelected={setSelected}
                                      selected={selected.pageId === el.pageId}
                                  />
                              ))
                            : null}
                    </List>
                ) : (
                    <List
                        sx={{
                            minWidth: '100%',
                            minHeight: '100%',
                            maxHeight: '100%',
                            maxWidth: '100%'
                        }}>
                        {fake.map((el) => (
                            <LoadingItem key={el.id} />
                        ))}
                    </List>
                )}
            </Box>
        </Box>
    )
}
