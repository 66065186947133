import axios from 'axios'
import Store from './Store'

export default class TimelineStore extends Store {
    private source = axios.CancelToken.source()

    public getYoutubeTimeline = async (access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/timeline/youtube/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data
            console.log(response)

            if (response && response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }

            throw error
        }
    }

    public getTwitterTimeline = async (access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/timeline/twitter/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data
            if (response && response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }

            throw error
        }
    }

    public Cancel = () => {
        this.source.cancel()
    }
}
