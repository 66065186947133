import React, { useState, useEffect } from 'react'
import {
    Box,
    FormGroup,
    FormControl,
    Typography,
    useTheme,
    InputBase,
    Select,
    MenuItem
} from '@mui/material'
import Dropzone from './Dropzone'
import YoutubeStore from '../../services/store/Youtube'

interface IProps {
    access: string
    playlist: string
    setPlaylist: any
    title: string
    setTitle: any
    text: string
    setText: any
    file: Array<any>
    setFile: any
}

export default ({
    text,
    setText,
    file,
    setFile,
    title,
    setTitle,
    access,
    playlist,
    setPlaylist
}: IProps) => {
    const theme = useTheme()
    const [data, setData] = useState<Array<any>>([])

    useEffect(() => {
        const API = new YoutubeStore()
        ;(async () => {
            const response = await API.getPlaylist(access)
            if (!response.canceled && Array.isArray(response)) {
                setData(response)
                if (response.length > 0) setPlaylist(response[0].id)
            }
        })()

        return () => {
            API.Cancel()
        }
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                minHeight: '100%',
                maxHeight: '100%'
            }}>
            <FormGroup>
                <FormControl>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                        Séléctionnez une playlist
                    </Typography>
                    <Select
                        value={playlist}
                        onChange={(e) => setPlaylist(e.target.value)}
                        displayEmpty
                        size="small"
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 5,
                            minWidth: '90%',
                            maxWidth: '90%',
                            padding: 2
                        }}>
                        {data.length === 0 ? (
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                        ) : null}
                        {data.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.snippet.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                        Titre de la vidéo
                    </Typography>
                    <InputBase
                        value={title}
                        onChange={(e) => setTitle(e.currentTarget.value)}
                        placeholder="Titre"
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 5,
                            minWidth: '90%',
                            maxWidth: '90%',
                            padding: 2
                        }}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                        Decrivez votre vidéo
                    </Typography>
                    <InputBase
                        multiline
                        minRows={3}
                        value={text}
                        onChange={(e) => setText(e.currentTarget.value)}
                        placeholder="Description"
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 5,
                            minWidth: '90%',
                            maxWidth: '90%',
                            padding: 2
                        }}
                    />
                </FormControl>
            </FormGroup>
            <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                Choisissez des photos ou des vidéo
            </Typography>
            <Dropzone file={file} setFile={setFile} />
        </Box>
    )
}
