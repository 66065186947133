import React from 'react'
import { useSprings, animated } from '@react-spring/web'
import Facebook from '../../assets/images/facebook.png'
import Instagram from '../../assets/images/instagram.png'
import Youtube from '../../assets/images/youtube.png'
import Twitter from '../../assets/images/twitter.png'
import Pinterest from '../../assets/images/pinterest.png'

interface IProps {
    height: number
    width: number
    animating: boolean
}

export default ({ height, width, animating }: IProps) => {
    const logos = [
        { id: 'facebook', url: Facebook },
        { id: 'instagram', url: Instagram },
        { id: 'youtube', url: Youtube },
        { id: 'pinterest', url: Pinterest },
        { id: 'twitter', url: Twitter }
    ]

    const springs = useSprings(
        logos.length,
        logos.map((logo, index) => ({
            from: {
                x: /* Math.random() < 0.5 ? -500 : width + 500 */ width,
                y: 0,
                opacity: 0,
                rotate: 0,
                scale: 1
            },
            to: async (next: any) => {
                // eslint-disable-next-line no-constant-condition
                while (animating) {
                    await next({ y: height / 2, opacity: 1, scale: 1.5, rotate: 720 })
                    await next({
                        x: Math.random() * width + 200,
                        rotate: 1440,
                        scale: 0.5
                    })
                }
            },
            delay: index * 100,
            config: { mass: 1, tension: 120, friction: 14 }
        }))
    )

    return (
        <div>
            {springs.map((style, index) => (
                <animated.div key={logos[index].id} style={style}>
                    <img
                        src={logos[index].url}
                        alt={logos[index].id}
                        style={{ width: '100px', height: '100px' }}
                    />
                </animated.div>
            ))}
        </div>
    )
}
