/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Box,
    Avatar,
    Typography,
    Button,
    List,
    ListItem,
    IconButton,
    ListItemAvatar,
    ListItemText,
    Skeleton,
    Modal,
    Divider
} from '@mui/material'
import { AlertColor } from '@mui/material/Alert'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import PersonIcon from '@mui/icons-material/Person'
import Create from './Create'
import Snack from '../sous-components/Snack'
import Facebook from '../../assets/images/facebook.png'
import Instagram from '../../assets/images/instagram.png'
import Youtube from '../../assets/images/youtube.png'
import Twitter from '../../assets/images/twitter.png'
import Pinterest from '../../assets/images/pinterest.png'
import CommunityStore from '../../services/store/Community'
import AccountStore from '../../services/store/Account'
import Introduce from './Introduce'

interface IProps {
    account: any
}

const fake = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    },
    {
        id: 5
    }
]

export default ({ account }: IProps) => {
    const [reload, setReload] = useState(false)
    const [open, setOpen] = useState(false)
    const [manager, setManager] = useState<Array<any> | null>(null)
    const [community, setCommunity] = useState<Array<any> | null>(null)
    const [openSnack, setOpenSnack] = useState(false)
    const [message, setMessage] = useState('')
    const [snackType, setSnackType] = useState<AlertColor>('success')

    useEffect(() => {
        setReload(true)
        const timeout = setTimeout(() => {
            setReload(false)
        }, 500)

        return () => {
            clearTimeout(timeout)
        }
    }, [account])

    useEffect(() => {
        const API = new CommunityStore()
        ;(async () => {
            const response = await API.getCommunity()
            if (!response.canceled) {
                setCommunity(response)
            }
        })()

        return () => {
            API.Cancel()
        }
    }, [account])

    useEffect(() => {
        const API = new AccountStore()
        ;(async () => {
            const response = await API.getManagerAccount(account.id)
            if (!response.canceled) {
                setManager(response)
            }
        })()

        return () => {
            API.Cancel()
        }
    }, [account])

    useEffect(() => {
        const filtered = filterCommunity()
        setCommunity(filtered)
    }, [manager, reload])

    const renderMedia = () => {
        switch (account.type) {
            case 'facebook':
                return Facebook
            case 'instagram':
                return Instagram
            case 'youtube':
                return Youtube
            case 'twitter':
                return Twitter
            case 'pinterest':
                return Pinterest
            default:
                return Facebook
        }
    }

    const filterCommunity = () => {
        if (Array.isArray(community) && Array.isArray(manager)) {
            const filtered = community.filter(
                (el: any) => !manager.find((item: any) => el.id === item.id)
            )
            return filtered
        }
        return community
    }

    const onAdd = async (user_id: string | number) => {
        const API = new AccountStore()
        const response = await API.addCommunity(user_id, account.id)
        if (response) {
            setManager(null)
            const result = await API.getManagerAccount(account.id)
            if (!result.canceled) {
                setManager(result)
            }
            setMessage(`Le community manager a été assigné`)
            setSnackType('success')
        } else {
            setMessage(`Une erreur s'est produite`)
            setSnackType('error')
        }
        const timeout = setTimeout(() => {
            setOpenSnack(false)
            setMessage('')
            clearTimeout(timeout)
        }, 3000)
    }

    const onRemove = async (user_id: string | number) => {
        const API = new AccountStore()
        const response = await API.removeCommunity(user_id, account.id)
        if (response) {
            setManager(null)
            const result = await API.getManagerAccount(account.id)
            if (!result.canceled) {
                setManager(result)
                await onReloadCommunity()
            }
            setMessage(`Le community manager a été retiré!`)
            setSnackType('success')
        } else {
            setMessage(`Une erreur s'est produite`)
            setSnackType('error')
        }
        const timeout = setTimeout(() => {
            setOpenSnack(false)
            setMessage('')
            clearTimeout(timeout)
        }, 3000)
    }

    const onReloadCommunity = async () => {
        setCommunity(null)
        const API = new CommunityStore()
        const response = await API.getCommunity()
        if (!response.canceled) {
            const timeout = setTimeout(() => {
                setCommunity(response)
                clearTimeout(timeout)
            }, 2000)
        }
        API.Cancel()
    }

    if (reload || !account) return <Introduce />

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                minHeight: '100%',
                minWidth: '100%'
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    minHeight: '30%',
                    minWidth: '100%'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100%',
                        minWidth: '40%'
                    }}>
                    <img src={renderMedia()} alt="avatar" style={{ height: 150, width: 150 }} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100%',
                        minWidth: '60%'
                    }}>
                    <Box>
                        <Typography component="h4" variant="h4">
                            Gérer vos équipes
                        </Typography>
                        <Typography paragraph>
                            Ajoutez, supprimer, assigner et retirer des community managers.
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        color="info"
                        endIcon={<AddIcon />}
                        onClick={() => setOpen(true)}>
                        Créer un community
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    minHeight: '70%',
                    minWidth: '100%'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                        minHeight: '100%',
                        minWidth: '45%'
                    }}>
                    <Typography component="h5" variant="h5">
                        Assignées :
                    </Typography>
                    {manager ? (
                        <List>
                            {manager.map((el: any) => (
                                <ListItem
                                    secondaryAction={
                                        el.type === 'owner' ? null : (
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => onRemove(el.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )
                                    }>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={`${el.prenom} ${el.nom}`} />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <List>
                            {fake.map((el) => (
                                <ListItem key={el.id}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Skeleton
                                        variant="text"
                                        width={150}
                                        sx={{ fontSize: '2rem' }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
                <Divider />
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                        minHeight: '100%',
                        minWidth: '45%'
                    }}>
                    <Typography component="h5" variant="h5">
                        Community manager :
                    </Typography>
                    {community ? (
                        <List>
                            {community.map((el: any) => (
                                <ListItem
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="add"
                                            onClick={() => onAdd(el.id)}>
                                            <AddIcon />
                                        </IconButton>
                                    }>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={`${el.prenom} ${el.nom}`} />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <List>
                            {fake.map((el) => (
                                <ListItem key={el.id}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Skeleton
                                        variant="text"
                                        width={150}
                                        sx={{ fontSize: '2rem' }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <Create onReload={onReloadCommunity} />
            </Modal>
            <Snack
                type={snackType}
                message={message}
                handleClose={() => setOpenSnack(false)}
                open={openSnack}
            />
        </Box>
    )
}
