/* eslint-disable no-shadow */
import axios from 'axios'
import Store from './Store'

/* eslint-disable no-unused-vars */
export enum Role {
    ADMIN = 'admin',
    COMMUNITY = 'community',
    USER = 'user'
}

export enum Sexe {
    MASCULIN = 'masculin',
    FEMININ = 'feminin'
}

/* eslint-enable no-unused-vars */

/** interface IUser {
    nom: string
    prenom: string
    email: string
    password: string
    birth: number | string
    sexe: Sexe
    role: Role
    facebook?: string
    google?: string
    apple?: string
} */

interface IAuth {
    email: string
    password: string
}

export default class OAuth extends Store {
    private source = axios.CancelToken.source()

    public Signup = async (data: any) => {
        try {
            const response = (await axios.post(`${this.url}/auth/signup`, data)).data
            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public Signin = async (data: IAuth) => {
        try {
            const response = (await axios.post(`${this.url}/auth/signin`, data)).data
            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public FacebookLink = async () => {
        try {
            const response = (await axios.get(`${this.url}/auth/link/facebook`)).data
            if (response.success) return response.link

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public GoogleLink = async () => {
        try {
            const response = (await axios.get(`${this.url}/auth/link/google`)).data
            if (response.success) return response.link

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public verifyToken = async () => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/token`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data
            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public refreshToken = async (refresh: string) => {
        try {
            const response = (await axios.get(`${this.url}/auth/token/refresh/${refresh}`)).data
            if (!response.success) return { canceled: true }

            return response.token
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public Cancel = () => {
        this.source.cancel()
    }
}
