/* eslint-disable react/jsx-fragments */
import React from 'react'
import { useTheme } from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Skeleton from '@mui/material/Skeleton'

export default () => {
    const theme = useTheme()

    return (
        <Card
            sx={{
                maxWidth: '100%',
                minWidth: '100%',
                minHeight: 500,
                marginBottom: 2,
                backgroundColor: theme.palette.primary.main
            }}>
            <CardHeader
                avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
                action={null}
                title={
                    <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                    />
                }
                subheader={<Skeleton animation="wave" height={10} width="40%" />}
            />
            <Skeleton sx={{ height: 320 }} animation="wave" variant="rectangular" />
            <CardContent>
                <React.Fragment>
                    <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={10} width="80%" />
                </React.Fragment>
            </CardContent>
        </Card>
    )
}
