/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { Box, Card, CardHeader, Avatar, CardContent, Typography, useTheme } from '@mui/material'
import { red } from '@mui/material/colors'
import Youtube from 'react-youtube'
import moment from 'moment'
import TimelineStore from '../../services/store/Timeline'
import TimelineLoading from './TimelineLoading'
import YoutubeIcon from '../../assets/images/youtube.png'

interface IProps {
    account: any
}

interface IIProps {
    data: any
}

const Item = ({ data }: IIProps) => {
    const theme = useTheme()

    return (
        <Card
            sx={{
                maxWidth: '100%',
                minWidth: '100%',
                minHeight: 500,
                marginBottom: 2,
                backgroundColor: theme.palette.primary.main
            }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        <img src={YoutubeIcon} alt="avatar" style={{ height: 25, width: 25 }} />
                    </Avatar>
                }
                title={`${data.snippet.channelTitle}`}
                subheader={moment(data.snippet.publishedAt).calendar()}
            />
            {/** <CardMedia
                component="video"
                height="194"
                src={`https://www.youtube.com/watch?v=${data.id}`}
            /> */}
            <CardContent
                sx={{
                    padding: 0
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: 194,
                        paddingLeft: 0
                    }}>
                    <Typography variant="body2" color="text.secondary" sx={{ marginLeft: '10px' }}>
                        {`   ${data.snippet.title}`}
                    </Typography>
                    <Youtube videoId={data.id} style={{ width: 100 }} />
                </Box>
            </CardContent>
        </Card>
    )
}

export default ({ account }: IProps) => {
    const [data, setData] = useState<Array<any> | null>(null)

    useEffect(() => {
        const API = new TimelineStore()
        let isSubscribed = true
        if (isSubscribed) {
            ;(async () => {
                if (account) {
                    const accessData = {
                        id: account.id,
                        type: account.type,
                        pageId: account.pageId
                    }

                    const encodedAccess = btoa(JSON.stringify(accessData))
                    const response = await API.getYoutubeTimeline(encodedAccess)
                    console.log(response)
                    if (!response.canceled) {
                        setData(response)
                    }
                }
            })()
        }

        return () => {
            isSubscribed = false
            API.Cancel()
        }
    }, [account])

    if (!data) return <TimelineLoading />

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: '110%',
                minWidth: '100%',
                overflow: 'scroll'
            }}>
            {data.map((item: any) => (
                <Item key={`${item.id}`} data={item} />
            ))}
        </Box>
    )
}
