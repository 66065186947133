import React, { useState, useEffect } from 'react'
import {
    Card,
    CardHeader,
    Avatar,
    IconButton,
    CardMedia,
    CardContent,
    Typography,
    useTheme
} from '@mui/material'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import Pinterest from '../../assets/images/pinterest.png'
import NoImage from '../../assets/images/noimage.jpeg'

interface IProps {
    type: string
    data: any
    onClose: any
}

export default ({ data, type, onClose }: IProps) => {
    const Theme = useTheme()
    const [media, setMedia] = useState<any>(null)

    useEffect(() => {
        setMedia(renderMedia())
    }, [type])

    const renderMedia = () => {
        switch (type) {
            case 'pinterest':
                return Pinterest
            default:
                return NoImage
        }
    }

    return (
        <Card
            sx={{
                maxWidth: '40%',
                minWidth: '40%',
                minHeight: 200,
                backgroundColor: Theme.palette.primary.main
            }}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe">
                        {media ? (
                            <img src={media} alt="avatar" style={{ height: 25, width: 25 }} />
                        ) : (
                            'P'
                        )}
                    </Avatar>
                }
                action={
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
                title={data.board_owner.username}
                subheader={moment(data.created_at).calendar()}
            />
            <CardMedia
                component="img"
                height="194"
                image={data.media.images.originals.url ?? NoImage}
                alt="Image"
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {data.description}
                </Typography>
            </CardContent>
        </Card>
    )
}
