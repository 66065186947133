import React, { useState, useEffect } from 'react'
import { Box, Container, useTheme } from '@mui/material'
import Header from './Main/Header'
import Menu from './Main/Menu'
import Account from './Account'
import Publish from './Publish'
import Timeline from './Timeline'
import Team from './Team'
import Board from './Board'
import Metrics from './Metrics'
import LoadingScreen from './Animation/LoadingScreen'
// import RollingScreen from './Animation/RollingScreen'
import './styles/Main.css'

interface IProps {
    routeName: string
}

export default ({ routeName }: IProps) => {
    const theme = useTheme()
    const { innerWidth, innerHeight } = window
    const [width, setWidth] = useState(innerWidth)
    const [height, setHeight] = useState(innerHeight)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const timeout = setTimeout(() => {
            setLoading(false)
        }, 5000)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    useEffect(() => {
        const onResize = () => {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        }

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    const renderScreen = () => {
        switch (routeName) {
            case 'account':
                return <Account />
            case 'publication':
                return <Publish />
            case 'feed':
                return <Timeline />
            case 'team':
                return <Team />
            case 'board':
                return <Board />
            case 'stats':
                return <Metrics />
            default:
                return null
        }
    }

    if (loading) return <LoadingScreen />

    return (
        <Container
            maxWidth="xl"
            sx={{
                padding: '0px',
                marginLeft: '-2%',
                minHeight: height,
                maxHeight: height,
                minWidth: width,
                maxWidth: width,
                backgroundColor: theme.palette.background.default
            }}>
            <Header />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    minWidth: width,
                    maxWidth: width,
                    minHeight: '90%',
                    maxHeight: '90%'
                }}>
                <Menu routeName={routeName} />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        minWidth: '78%',
                        maxWidth: '78%',
                        minHeight: height - (height * 15) / 100,
                        maxHeight: height - (height * 15) / 100,
                        paddingTop: 1
                    }}>
                    {renderScreen()}
                </Box>
            </Box>
        </Container>
    )
}
