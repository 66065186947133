import React, { useState, useEffect } from 'react'
import { Box, useTheme } from '@mui/material'
import List from './sous-components/List'
import Publish from './Publish/Publish'
import Introduce from './Publish/Introduce'

export default () => {
    const theme = useTheme()
    const [selected, setSelected] = useState<any>(null)
    const { innerHeight } = window
    const [height, setHeight] = useState(innerHeight)

    useEffect(() => {
        const onResize = () => {
            if (height !== window.innerHeight) {
                setHeight(window.innerHeight)
            }
        }

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return (
        <Box
            sx={{
                minWidth: '100%',
                minHeight: height - (height * 15) / 100,
                maxHeight: height - (height * 15) / 100,
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                backgroundColor: theme.palette.secondary.main
            }}>
            <Box
                sx={{
                    minWidth: '27%',
                    minHeight: '100%',
                    maxHeight: '100%',
                    maxWidth: '27%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRightWidth: '1px',
                    borderColor: '#C3C3C3',
                    backgroundColor: theme.palette.primary.main,
                    boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.5)',
                    borderRadius: 2
                }}>
                <List selected={selected} setSelected={setSelected} />
            </Box>
            <Box
                sx={{
                    minWidth: '71%',
                    minHeight: '100%',
                    maxHeight: '100%',
                    maxWidth: '71%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.palette.primary.main,
                    boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.5)',
                    borderRadius: 2,
                    padding: 1
                }}>
                {selected ? <Publish account={selected} /> : <Introduce />}
            </Box>
        </Box>
    )
}
