import React, { useState, useMemo, useEffect } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'
import { RouterProvider } from 'react-router-dom'
import { ThemeContext } from './services/context/context'
import { getDesignTokens } from './theme/theme'
import { router } from './route/route'
import { verifyToken } from './services/factory/OAuth'
import PageTransition from './components/Animation/PageTransition'
import './App.css'
import './components/styles/transition.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'react-activity/dist/library.css'

export default () => {
    const [mode, setMode] = useState<PaletteMode>('light')
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'))
            }
        }),
        []
    )

    const theme = React.useMemo(() => createTheme({ ...getDesignTokens(mode) }), [mode])

    useEffect(() => {
        ;(async () => {
            const verified = await verifyToken()
            if (verified) console.log(`Token verified`)
            else console.log(`Failed to verify token`)
        })()
    }, [])

    return (
        <ThemeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <PageTransition>
                    <RouterProvider router={router} />
                </PageTransition>
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}
