import React, { useState, useEffect } from 'react'
import { ListItemButton, ListItemAvatar, Avatar, ListItemText } from '@mui/material'
import Facebook from '../../assets/images/facebook.png'
import Instagram from '../../assets/images/instagram.png'
import Youtube from '../../assets/images/youtube.png'
import Twitter from '../../assets/images/twitter.png'
import Pinterest from '../../assets/images/pinterest.png'

interface IProps {
    data: any
    setSelected: any
    selected: boolean
}

export default ({ data, setSelected, selected }: IProps) => {
    const [media, setMedia] = useState<any>(Facebook)

    const renderMedia = () => {
        switch (data.type) {
            case 'facebook':
                return Facebook
            case 'instagram':
                return Instagram
            case 'youtube':
                return Youtube
            case 'twitter':
                return Twitter
            case 'pinterest':
                return Pinterest
            default:
                return Facebook
        }
    }

    useEffect(() => {
        setMedia(renderMedia())
    }, [])

    return (
        <ListItemButton
            onClick={() => setSelected(data)}
            sx={{
                boxShadow: selected
                    ? '1px 3px 5px rgba(0, 0, 0, 0.5)'
                    : '0px 0px 0px rgba(0, 0, 0, 0)'
            }}>
            <ListItemAvatar>
                <Avatar>
                    <img src={media} alt="avatar" style={{ height: 25, width: 25 }} />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={data.name} secondary={`${data.email}`} />
        </ListItemButton>
    )
}
