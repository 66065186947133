/* eslint-disable no-restricted-syntax */
import axios from 'axios'
import Store from './Store'

export default class PinterestStore extends Store {
    private source = axios.CancelToken.source()

    public getBoard = async (access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/pinterest/board/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getSection = async (board_id: string, access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/pinterest/section/${access}?board_id=${board_id}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public createBoard = async (data: any, access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const formdata = new FormData()
            formdata.append('name', data.name)
            formdata.append('description', data.description)
            formdata.append('privacy', data.privacy)

            const response = (
                await axios.post(`${this.url}/pinterest/board/create/${access}`, formdata, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            return response.success
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public createSection = async (data: any, access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const formdata = new FormData()
            formdata.append('name', data.name)
            formdata.append('board_id', data.board_id)

            const response = (
                await axios.post(`${this.url}/pinterest/section/create/${access}`, formdata, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            return response.success
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public createPost = async (data: any, access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const formdata = new FormData()
            formdata.append('link', data.link)
            formdata.append('description', data.text)
            formdata.append('title', data.title)
            formdata.append('text', data.alt_text)
            formdata.append('board_id', data.board)
            formdata.append('section', data.section)
            if (data.file && data.file.length > 0) {
                formdata.append('file', data.file[0])
            }

            const response = (
                await axios.post(`${this.url}/pinterest/pin/create/${access}`, formdata, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getPost = async (id: string, access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            console.log('get Pin')

            const response = (
                await axios.get(`${this.url}/pinterest/pin/${id}/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getPosts = async (access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/pinterest/pins/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getStats = async (access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/pinterest/stats/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public Cancel = () => {
        this.source.cancel()
    }
}
