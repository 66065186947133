import React, { useEffect, useState, useRef } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Box } from '@mui/material'
import TwitterStore from '../../services/store/Twitter'

interface IProps {
    account: any
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const
        },
        title: {
            display: true,
            text: 'Statistiques de la page twitter'
        }
    }
}

export default ({ account }: IProps) => {
    const chart = useRef<any>()
    const [data, setData] = useState<any>(null)

    useEffect(() => {
        const API = new TwitterStore()
        let isSubscribed = true
        ;(async () => {
            if (account && isSubscribed) {
                const accountInfo = {
                    id: account.id,
                    type: account.type,
                    pageId: account.pageId
                }
                const encodedAccess = btoa(JSON.stringify(accountInfo))
                const response = await API.getStats(encodedAccess)
                if (!response.canceled) {
                    if (chart.current) chart.current.chartInstance.destroy()
                    setData(response)
                }
            }
        })()

        return () => {
            API.Cancel()
            isSubscribed = false
        }
    }, [account])

    if (!data) return null

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                maxWidth: '100%',
                minHeight: '100%',
                maxHeight: '100%',
                padding: '20px'
            }}>
            <Bar ref={chart} data={data} options={options} />
        </Box>
    )
}
