import React, { useState, useEffect } from 'react'
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
    Modal,
    Box
} from '@mui/material'
import { /* Delete, */ Settings } from '@mui/icons-material'
import Facebook from '../../assets/images/facebook.png'
import Instagram from '../../assets/images/instagram.png'
import Youtube from '../../assets/images/youtube.png'
import Twitter from '../../assets/images/twitter.png'
import Pinterest from '../../assets/images/pinterest.png'
import SettingYoutube from './Youtube'
import SettingPinterest from './Pinterest'

interface IProps {
    data: any
}

export default ({ data }: IProps) => {
    const [media, setMedia] = useState<any>(Facebook)
    const [open, setOpen] = useState(false)

    const renderMedia = () => {
        switch (data.type) {
            case 'facebook':
                return Facebook
            case 'instagram':
                return Instagram
            case 'youtube':
                return Youtube
            case 'twitter':
                return Twitter
            case 'pinterest':
                return Pinterest
            default:
                return Facebook
        }
    }

    const renderSetting = () => {
        switch (data.type) {
            case 'youtube':
                return <SettingYoutube account={data} />
            case 'pinterest':
                return <SettingPinterest account={data} />
            default:
                return <Box></Box>
        }
    }

    const onOpen = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        setMedia(renderMedia())
    }, [])

    const needSetting = () => {
        if (data.type === 'youtube' || data.type === 'pinterest') return true

        return false
    }

    return (
        <Card sx={{ maxWidth: 345, margin: 1 }}>
            <CardMedia component="img" alt="green iguana" height={200} image={media} />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {data.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {`You've allowed community manager to use your ${data.type} account`}
                </Typography>
            </CardContent>
            <CardActions>
                {/* <Button size="small">
                    <Delete />
    </Button> */}
                {needSetting() ? (
                    <Button type="button" onClick={onOpen}>
                        <Settings />
                    </Button>
                ) : null}
            </CardActions>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                {renderSetting()}
            </Modal>
        </Card>
    )
}
