/* eslint-disable no-alert */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Button, useTheme } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import Signup from './Home/Signup'
import Signin from './Home/Signin'
import Social from './Home/SocialAuth'
import LoadingScreen from './Animation/LoadingScreen'
import LightBackground from '../assets/images/HomeBackgroundPostin.png'
import DarkBackground from '../assets/images/HomeDarkBackgroundPostin.png'
import AuthStore from '../services/store/Auth'
import './styles/home.css'

export default () => {
    const { innerHeight, innerWidth } = window
    const { state } = useParams()
    const navigate = useNavigate()
    const Theme = useTheme()
    const [login, setLogin] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const timeout = setTimeout(() => {
            setLoading(false)
        }, 5000)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    useEffect(() => {
        const API = new AuthStore()
        if (state && state !== '') {
            const decodedState = atob(state)
            const decoded = JSON.parse(decodedState)
            if (decoded.success) {
                const expireIn = moment().add('1d').unix()
                localStorage.setItem('expireIn', `${expireIn}`)
                localStorage.setItem('refresh_token', decoded.refreshToken)
                localStorage.setItem('token', decoded.token)
            } else alert(`error: ${decoded.message}`)
        }
        // localStorage.clear()
        const token = localStorage.getItem('token')
        if (token && token !== '') {
            ;(async () => {
                const response = await API.verifyToken()
                if (!response.canceled) {
                    localStorage.setItem('id', response.id)
                    localStorage.setItem('name', `${response.prenom} ${response.nom}`)
                    navigate('/account')
                }
            })()
        }

        return () => {
            API.Cancel()
        }
    }, [])

    if (loading) return <LoadingScreen />

    return (
        <div
            id="home-container"
            style={{
                maxWidth: innerWidth,
                minWidth: innerWidth,
                minHeight: innerHeight,
                maxHeight: innerHeight,
                backgroundImage:
                    Theme.palette.mode === 'light'
                        ? `url(${LightBackground})`
                        : `url(${DarkBackground})`
            }}>
            <div
                id="home-wrapper"
                style={{
                    maxWidth: innerWidth / 2,
                    minWidth: innerWidth / 2,
                    minHeight: innerHeight,
                    maxHeight: innerHeight
                }}>
                <h1>{login ? 'Bienvenue sur Post-in !' : 'Créer votre compte Post-in'}</h1>
                {login ? <Signin /> : <Signup />}
                <Social />
                <div id="auth-type">
                    <p>{login ? `Pas de compte ? ` : `Vous avez déjà un compte ? `}</p>
                    <Button variant="text" color="info" onClick={() => setLogin(!login)}>
                        {login ? `S'inscrire` : `Se connecter`}
                    </Button>
                </div>
                <div id="footer">
                    <div id="engagement">
                        <p>
                            En poursuivant, j’accepte les{' '}
                            <a href="#"> Conditions d’utilisation du compte</a> et les{' '}
                            <a href="#">Politique de confidentialité</a>
                        </p>
                    </div>
                    <div id="language">
                        <p>
                            Français (France) Malagasy English (US) Italiano Español Deutsch Türkçe
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
