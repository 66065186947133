/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { Tweet } from 'react-twitter-widgets'
import TimelineStore from '../../services/store/Timeline'
import TimelineLoading from './TimelineLoading'

interface IProps {
    account: any
}

/** interface IIProps {
    data: any
}

const Item = ({ data }: IIProps) => {
    const theme = useTheme()

    return (
        <Card
            sx={{
                maxWidth: '100%',
                minWidth: '100%',
                minHeight: data.media ? 600 : 200,
                marginBottom: 2,
                backgroundColor: theme.palette.primary.main
            }}>
            <CardHeader
                avatar={
                    data.profile_image_url && data.profile_image_url !== '' ? (
                        <img src={data.profile_image_url} alt="Profil" />
                    ) : (
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            T
                        </Avatar>
                    )
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                title={`${data.username}`}
                subheader={moment(data.created_at).format('DD-MMMM-YYYY HH:mm')}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {`${data.text}`}
                </Typography>
            </CardContent>
            {data.media ? (
                <CardMedia component="img" height="450" src={`${data.media[0].url}`} />
            ) : null}
        </Card>
    )
} */

export default ({ account }: IProps) => {
    const [data, setData] = useState<Array<any> | null>(null)

    useEffect(() => {
        const API = new TimelineStore()
        let isSubscribed = true
        if (isSubscribed) {
            ;(async () => {
                if (account) {
                    const accessData = {
                        id: account.id,
                        type: account.type,
                        pageId: account.pageId
                    }

                    const encodedAccess = btoa(JSON.stringify(accessData))
                    const response = await API.getTwitterTimeline(encodedAccess)
                    console.log(response)
                    if (!response.canceled) {
                        setData(response)
                    }
                }
            })()
        }

        return () => {
            isSubscribed = false
            API.Cancel()
        }
    }, [account])

    if (!data) return <TimelineLoading />

    return (
        <Box
            sx={{
                minHeight: '110%',
                minWidth: '100%',
                overflow: 'scroll'
            }}>
            {data.map((item: any) => (
                <Tweet key={`tweet${item.id}`} tweetId={item.id} options={{ width: '100%' }} />
            ))}
        </Box>
    )
}
