import React from 'react'
import { Box, Skeleton, ListItemAvatar, ListItemButton } from '@mui/material'

export default () => (
    <ListItemButton>
        <ListItemAvatar>
            <Skeleton variant="circular" width={40} height={40} />
        </ListItemAvatar>
        <Box>
            <Skeleton variant="text" width={150} sx={{ fontSize: '2rem' }} />
            <Skeleton variant="text" width={200} sx={{ fontSize: '1rem' }} />
        </Box>
    </ListItemButton>
)
