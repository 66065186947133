import React, { useState, useEffect } from 'react'
import {
    Card,
    CardHeader,
    Avatar,
    IconButton,
    CardMedia,
    CardContent,
    Typography,
    useTheme,
    CardActions
} from '@mui/material'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import ModeCommentIcon from '@mui/icons-material/ModeComment'
import FavoriteIcon from '@mui/icons-material/Favorite'
import Facebook from '../../assets/images/facebook.png'
import NoImage from '../../assets/images/noimage.jpeg'

interface IProps {
    type: string
    data: any
    onClose: any
}

export default ({ data, type, onClose }: IProps) => {
    const Theme = useTheme()
    const [media, setMedia] = useState<any>(null)

    useEffect(() => {
        setMedia(renderMedia())
        console.log('ouvert')
    }, [type])

    const renderMedia = () => {
        switch (type) {
            case 'facebook':
                return Facebook
            default:
                return NoImage
        }
    }

    if (!data) return null

    return (
        <Card
            sx={{
                maxWidth: '40%',
                minWidth: '40%',
                minHeight: 200,
                backgroundColor: Theme.palette.primary.main
            }}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe">
                        {media ? (
                            <img src={media} alt="avatar" style={{ height: 25, width: 25 }} />
                        ) : (
                            'P'
                        )}
                    </Avatar>
                }
                action={
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
                title={data.from.name}
                subheader={moment(data.created_time).calendar()}
            />
            <CardMedia
                component={
                    data.attachments.type && data.attachments.type !== 'photo' ? 'video' : 'img'
                }
                height="194"
                image={data.attachments.src ?? NoImage}
                alt="Image"
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {data.message}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <Typography>{data.likes}</Typography>
                    <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <Typography>{data.comments}</Typography>
                    <ModeCommentIcon />
                </IconButton>
            </CardActions>
        </Card>
    )
}
