import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import Home from '../screens/Home'
import Main from '../screens/Main'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
        children: [
            {
                path: 'social/auth/:state',
                element: <Home />
            }
        ]
    },
    {
        path: '/account',
        element: <Main routeName="account" />,
        children: [
            {
                path: '/account/:message',
                element: <Main routeName="account" />
            }
        ]
    },
    {
        path: '/publication',
        element: <Main routeName="publication" />
    },
    {
        path: '/feed',
        element: <Main routeName="feed" />
    },
    {
        path: '/team',
        element: <Main routeName="team" />
    },
    {
        path: '/stats',
        element: <Main routeName="stats" />
    },
    {
        path: '/board',
        element: <Main routeName="board" />,
        children: [
            {
                path: '/board/:info',
                element: <Main routeName="board" />
            }
        ]
    }
])
