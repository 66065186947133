/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, MouseEvent } from 'react'
import {
    FormGroup,
    FormControl,
    InputAdornment,
    TextField,
    IconButton,
    InputLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Alert,
    Box,
    Button,
    useTheme
} from '@mui/material'
import { AlertColor } from '@mui/material/Alert'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FilledInput from '@mui/material/FilledInput'
import moment from 'moment'
import CommunityStore from '../../services/store/Community'
import Snack from '../sous-components/Snack'

interface IProps {
    onReload: any
}

export default ({ onReload }: IProps) => {
    const theme = useTheme()
    const { innerWidth } = window
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [date, setDate] = useState<string | null>('')
    const [sexe, setSexe] = useState('masculin')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [message, setMessage] = useState('')
    const [snackType, setSnackType] = useState<AlertColor>('success')

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
    }

    const handleClickShowConfirm = () => setShowConfirm((show) => !show)

    const handleMouseDownConfirm = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
    }

    const reinitilized = () => {
        setNom('')
        setPrenom('')
        setEmail('')
        setPassword('')
        setConfirm('')
        setDate('')
    }

    const onSignup = async () => {
        const API = new CommunityStore()
        const data = {
            nom,
            prenom,
            email,
            password,
            birth: moment(date).format('DD-MM-YYYY'),
            sexe,
            role: 'community'
        }
        const response = await API.createCommunity(data)
        if (response) {
            onReload()
            setMessage(`Community manager créé avec succès!`)
            setSnackType('success')
            reinitilized()
        } else {
            setMessage(`Une erreur s'est produite`)
            setSnackType('error')
        }
        const timeout = setTimeout(() => {
            setOpenSnack(false)
            setMessage('')
            clearTimeout(timeout)
        }, 3000)
    }

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: innerWidth / 2,
                maxWidth: innerWidth / 2,
                borderRadius: 2,
                backgroundColor: theme.palette.primary.main,
                padding: 4
            }}>
            {message !== '' ? <Alert severity="success">{message}</Alert> : null}
            <FormGroup id="name" className="group">
                <FormControl>
                    <TextField
                        type="text"
                        variant="standard"
                        placeholder="Nom"
                        value={nom}
                        onChange={(e) => setNom(e.currentTarget.value)}
                        required
                        className="name input"
                        size="small"
                        style={{
                            minWidth: innerWidth / 2 / 2 - 20,
                            maxWidth: innerWidth / 2 / 2 - 20,
                            backgroundColor: '#FFF'
                        }}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        type="text"
                        variant="standard"
                        placeholder="Prénom"
                        value={prenom}
                        onChange={(e) => setPrenom(e.currentTarget.value)}
                        required
                        className="name input"
                        size="small"
                        style={{
                            minWidth: innerWidth / 2 / 2 - 20,
                            maxWidth: innerWidth / 2 / 2 - 20,
                            backgroundColor: '#FFF'
                        }}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup className="group">
                <FormControl>
                    <TextField
                        type="text"
                        variant="standard"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        required
                        className="input"
                        size="small"
                        style={{ backgroundColor: '#FFF' }}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup className="group">
                <FormControl>
                    <FilledInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        placeholder="Mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        className="input"
                        size="small"
                        style={{ backgroundColor: '#FFF' }}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup className="group">
                <FormControl>
                    <FilledInput
                        id="outlined-adornment-password"
                        type={showConfirm ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirm}
                                    onMouseDown={handleMouseDownConfirm}
                                    edge="end">
                                    {showConfirm ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        placeholder="Confirmer"
                        value={confirm}
                        onChange={(e) => setConfirm(e.currentTarget.value)}
                        className="input"
                        size="small"
                        style={{ backgroundColor: '#FFF' }}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup id="birth">
                <InputLabel>Date de naissance</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        mask="__/__/____"
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="standard"
                                style={{
                                    minWidth: (innerWidth / 2 - 250) / 2 - 10,
                                    maxWidth: (innerWidth / 2 - 250) / 2 - 10,
                                    marginLeft: 10,
                                    backgroundColor: '#FFF'
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </FormGroup>
            <FormGroup className="group check">
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group">
                        <FormControlLabel
                            value="masculin"
                            onSelect={() => setSexe('masculin')}
                            control={<Radio size="small" />}
                            label="Homme"
                        />
                        <FormControlLabel
                            value="feminin"
                            onSelect={() => setSexe('feminin')}
                            control={<Radio size="small" />}
                            label="Femme"
                        />
                        <FormControlLabel
                            value="personnalise"
                            onSelect={() => setSexe('other')}
                            control={<Radio size="small" />}
                            label="personnalise"
                        />
                    </RadioGroup>
                </FormControl>
            </FormGroup>
            <Button variant="contained" color="info" onClick={onSignup}>
                Créer
            </Button>
            <Snack
                type={snackType}
                message={message}
                handleClose={() => setOpenSnack(false)}
                open={openSnack}
            />
        </Box>
    )
}
