import React, { useState, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import OAuth from '../../services/store/Auth'

export default () => {
    const { innerWidth } = window
    const [width, setWidth] = useState(innerWidth / 3)
    const [google, setGoogle] = useState<string | null>(null)
    const [facebook, setFacebook] = useState<string | null>(null)

    useEffect(() => {
        const API = new OAuth()
        let isSubscribed = true
        ;(async () => {
            if (isSubscribed) {
                const response = await API.GoogleLink()
                if (!response.canceled) {
                    setGoogle(response)
                }
            }
        })()

        return () => {
            API.Cancel()
            isSubscribed = false
        }
    }, [])

    useEffect(() => {
        const API = new OAuth()
        let isSubscribed = true
        ;(async () => {
            if (isSubscribed) {
                const response = await API.FacebookLink()
                if (!response.canceled) {
                    setFacebook(response)
                }
            }
        })()

        return () => {
            API.Cancel()
            isSubscribed = false
        }
    }, [])

    useEffect(() => {
        const onResize = () => {
            if (innerWidth !== window.innerWidth) {
                setWidth(innerWidth / 3)
            }
        }

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return (
        <div id="social-container">
            <div id="social-title">
                <h6>ou utiliser votre compte</h6>
            </div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    minWidth: width,
                    maxWidth: width
                }}>
                {google ? (
                    <Button href="https://accounts.google.com/o/oauth2/v2/auth?client_id=555518876165-arqkg25crg1au1bl8io7psm6p9i8me5g.apps.googleusercontent.com&redirect_uri=https://api.postin.app/api/auth/google&scope=openid%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&response_type=code&access_type=offline">
                        <img
                            src="https://freesvg.org/img/1534129544.png"
                            alt="google"
                            className="social-icon"
                        />
                    </Button>
                ) : null}
                {facebook ? (
                    <Button href={facebook} >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png"
                            alt="facebook"
                            className="social-icon"
                        />
                    </Button>
                ) : null}
                {/** <Button href="#">
                    <img
                        src="https://cdn-icons-png.flaticon.com/512/152/152752.png"
                        alt="apple"
                        className="social-icon"
                    />
            </Button> */}
            </Box>
        </div>
    )
}
