/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { Box, Typography, FormGroup, FormControl, Button, TextField, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import dayjs, { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import SendIcon from '@mui/icons-material/Send'
import moment from 'moment'
import { AlertColor } from '@mui/material/Alert'
import {
    formatFacebookResponse,
    formatInstagramResponse,
    formatTweetResponse,
    formatPinResponse,
    formatYoutubeResponse,
    IPublishedFormated
} from '../../services/factory/Social'
import Twitter from './Twitter'
import Instagram from './Instagram'
import Facebook from './Facebook'
import Youtube from './Youtube'
import Pinterest from './Pinterest'
import TwitterStore from '../../services/store/Twitter'
import InstagramStore from '../../services/store/Instagram'
import FacebookStore from '../../services/store/Facebook'
import YoutubeStore from '../../services/store/Youtube'
import PinterestStore from '../../services/store/Pinterest'
import SheduledStore from '../../services/store/Sheduled'
import Snack from '../sous-components/Snack'

interface IProps {
    account: any
}

export default ({ account }: IProps) => {
    const navigate = useNavigate()
    const theme = useTheme()
    const [accountId, setAccountId] = useState<any>(null)
    const [pageId, setPageId] = useState<any>(null)
    const [playlist, setPlaylist] = useState('')
    const [section, setSection] = useState('')
    const [board, setBoard] = useState('')
    const [alt_text, setAlt_text] = useState('')
    const [access, setAccess] = useState('')
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [file, setFile] = useState<Array<File>>([])
    const [link, setLink] = useState('')
    const [pubDate, setPubDate] = useState<Dayjs | null>(dayjs())
    const [pubTime, setPubTime] = useState<Dayjs | null>(dayjs())
    const [reload, setReload] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [message, setMessage] = useState('')
    const [snackType, setSnackType] = useState<AlertColor>('success')
    const [publishWaiting, setPublishWaiting] = useState(false)

    useEffect(() => {
        const data = {
            id: account.id,
            type: account.type,
            pageId: account.pageId
        }
        setAccountId(account.id)
        setPageId(account.pageId)
        const encodedAccess = btoa(JSON.stringify(data))
        setAccess(encodedAccess)
    }, [account])

    useEffect(() => {
        setReload(true)
        const timeout = setTimeout(() => {
            setReload(false)
        }, 500)

        return () => {
            clearTimeout(timeout)
        }
    }, [account])

    const reinitialized = () => {
        setText('')
        setFile([])
        setLink('')
        setTitle('')
        setAlt_text('')
        setPubDate(dayjs())
        setPubTime(dayjs())
    }

    const onPlannify = async () => {
        if (pubTime && pubDate && accountId && pageId) {
            const API = new SheduledStore()
            const date = pubDate.format('YYYY-MM-DD')
            const time = pubTime.format('HH:mm')
            const data = {
                text,
                link,
                section,
                board,
                account_id: accountId,
                pageId,
                pageType: account.type,
                alt_text,
                title,
                playlist,
                creation_date: moment().format('YYYY-MM-DD HH:mm'),
                publication_date: `${date} ${time}`,
                file
            }
            const response = await API.CreateSheduled(data)
            if (response) {
                setSnackType('success')
                setOpenSnack(true)
                setMessage('Publication enregistrée !')
                reinitialized()
                const timeout = setTimeout(() => {
                    setOpenSnack(false)
                    setMessage('')
                    clearTimeout(timeout)
                }, 3000)
            }
        }
    }

    const onShare = async () => {
        try {
            setPublishWaiting(true)
            let response: any
            let info: IPublishedFormated | null = null

            const data = {
                text,
                link,
                file,
                playlist,
                title,
                section,
                board,
                alt_text
            }

            if (account.type === 'facebook') {
                const API = new FacebookStore()
                response = await API.createPost(data, access)
                if (!response.canceled) info = formatFacebookResponse(response)
            }

            if (account.type === 'instagram') {
                const API = new InstagramStore()
                response = await API.createPost(data, access)
                if (!response.canceled) info = formatInstagramResponse(response)
            }

            if (account.type === 'twitter') {
                const API = new TwitterStore()
                response = await API.createPost(data, access)
                if (!response.canceled) info = formatTweetResponse(response)
            }

            if (account.type === 'youtube') {
                const API = new YoutubeStore()
                response = await API.createPost(data, access)
                if (!response.canceled) info = formatYoutubeResponse(response)
            }

            if (account.type === 'pinterest') {
                const API = new PinterestStore()
                response = await API.createPost(data, access)
                if (!response.canceled) info = formatPinResponse(response)
            }

            if (!response.canceled) {
                reinitialized()
                setSnackType('success')
                setOpenSnack(true)
                setMessage('Publier avec succé !')
                const timeout = setTimeout(() => {
                    setOpenSnack(false)
                    setMessage('')
                    clearTimeout(timeout)
                }, 3000)
                if (info) {
                    const paramsData = {
                        ...info,
                        accountId: account.id,
                        pageId: account.pageId,
                        access
                    }
                    const params = btoa(JSON.stringify(paramsData))
                    navigate(`/board/${params}`)
                }
            } else {
                setSnackType('error')
                setOpenSnack(true)
                setMessage(`Une erreur s'est produit !`)
                const timeout = setTimeout(() => {
                    setOpenSnack(false)
                    setMessage('')
                    clearTimeout(timeout)
                }, 3000)
            }
            setPublishWaiting(false)
        } catch (error) {
            console.log(error)

            setSnackType('error')
            setOpenSnack(true)
            setMessage(`Une erreur s'est produit !`)
            const timeout = setTimeout(() => {
                setOpenSnack(false)
                setMessage('')
                clearTimeout(timeout)
            }, 3000)
            setPublishWaiting(false)
        }
    }

    const handleChangeDate = (newValue: Dayjs | null) => {
        setPubDate(newValue)
    }

    const handleChangeTime = (newValue: Dayjs | null) => {
        setPubTime(newValue)
    }

    const renderInput = () => {
        switch (account.type) {
            case 'facebook':
                return (
                    <Facebook
                        link={link}
                        text={text}
                        file={file}
                        setLink={setLink}
                        setText={setText}
                        setFile={setFile}
                    />
                )
            case 'instagram':
                return <Instagram text={text} file={file} setText={setText} setFile={setFile} />
            case 'twitter':
                return <Twitter text={text} file={file} setText={setText} setFile={setFile} />
            case 'youtube':
                return (
                    <Youtube
                        title={title}
                        setTitle={setTitle}
                        text={text}
                        setText={setText}
                        file={file}
                        setFile={setFile}
                        access={access}
                        playlist={playlist}
                        setPlaylist={setPlaylist}
                    />
                )
            case 'pinterest':
                return (
                    <Pinterest
                        access={access}
                        text={text}
                        file={file}
                        setText={setText}
                        setFile={setFile}
                        title={title}
                        setTitle={setTitle}
                        section={section}
                        setSection={setSection}
                        board={board}
                        setBoard={setBoard}
                        alt_text={alt_text}
                        setAlt_text={setAlt_text}
                        link={link}
                        setLink={setLink}
                    />
                )
            default:
                return <Twitter text={text} file={file} setText={setText} setFile={setFile} />
        }
    }

    if (reload) return null

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'row',
                justifyContent: 'space-around',
                minHeight: '100%',
                minWidth: '100%'
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                    minWidth: '60%',
                    maxWidth: '60%'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        minWidth: '100%',
                        maxWidth: '100%',
                        minHeight: 70,
                        maxHeight: 70
                    }}>
                    <Typography variant="h5">Création de Post</Typography>
                    {publishWaiting ? (
                        <LoadingButton loading={publishWaiting} size="medium" variant="contained">
                            {'PUBLIER  '}
                            <SendIcon sx={{ height: 15, width: 15 }} />
                        </LoadingButton>
                    ) : (
                        <Button size="medium" variant="contained" color="info" onClick={onShare}>
                            {'PUBLIER  '}
                            <SendIcon sx={{ height: 15, width: 15 }} />
                        </Button>
                    )}
                </Box>
                {renderInput()}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                    minWidth: '40%',
                    maxWidth: '40%'
                }}>
                <Box
                    sx={{
                        minWidth: '100%',
                        maxWidth: '100%',
                        minHeight: '10%',
                        maxHeight: '10%'
                    }}></Box>
                <Box>
                    <Typography variant="h6">PLANING</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: '100%',
                        maxWidth: '100%',
                        minHeight: '30%',
                        maxHeight: '30%',
                        backgroundColor: theme.palette.secondary.main
                    }}>
                    <FormGroup
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            minWidth: '95%',
                            maxWidth: '95%',
                            flexWrap: 'nowrap',
                            justifyContent: 'space-around'
                        }}>
                        <FormControl
                            variant="standard"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: '56%',
                                maxWidth: '56%'
                            }}>
                            <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                                Date de publication
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    inputFormat="DD-MM-YYYY"
                                    value={pubDate}
                                    onChange={handleChangeDate}
                                    renderInput={(params: any) => (
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            {...params}
                                            sx={{ backgroundColor: theme.palette.primary.main }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl
                            variant="standard"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: '42%',
                                maxWidth: '42%'
                            }}>
                            <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                                Heure optimal
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    value={pubTime}
                                    onChange={handleChangeTime}
                                    renderInput={(params: any) => (
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            {...params}
                                            sx={{ backgroundColor: theme.palette.primary.main }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </FormGroup>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        minWidth: '100%',
                        maxWidth: '100%',
                        minHeight: '10%',
                        maxHeight: '10%',
                        alignItems: 'center',
                        justifyContent: 'space-around'
                    }}>
                    <Button variant="outlined" color="info">
                        Cancel
                    </Button>
                    <Button variant="contained" color="info" onClick={onPlannify}>
                        Planifier
                    </Button>
                </Box>
            </Box>
            <Snack
                type={snackType}
                message={message}
                handleClose={() => setOpenSnack(false)}
                open={openSnack}
            />
        </Box>
    )
}
