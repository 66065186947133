/* eslint-disable no-restricted-syntax */
import axios from 'axios'
import Store from './Store'

export interface CreateSheduledPost {
    account_id: number
    type?: string
    creation_date: string
    publication_date: string
    pageId: string
    pageType: string
    text?: string
    link?: string
    board?: string
    section?: string
    file?: any
    title?: string
    alt_text?: string
    playlist?: string
}

export default class SheduledStore extends Store {
    private source = axios.CancelToken.source()

    public CreateSheduled = async (data: CreateSheduledPost) => {
        try {
            let type = 'text'
            const formdata = new FormData()

            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            if (data.link && data.link !== '') {
                type = 'link'
                formdata.append('link', data.link)
            }

            if (
                data.board &&
                data.board !== '' &&
                data.section &&
                data.section !== '' &&
                data.title &&
                data.title !== '' &&
                data.alt_text &&
                data.alt_text !== ''
            ) {
                formdata.append('board', data.board)
                formdata.append('section', data.section)
                formdata.append('alt_text', data.alt_text)
            }

            if (data.title && data.title !== '') formdata.append('title', data.title)

            if (data.playlist && data.playlist !== '') formdata.append('playlist', data.playlist)

            if (data.file.length > 0) {
                if (data.file[0].type.indexOf('image') !== -1) type = 'photo'
                if (data.file[0].type.indexOf('video') !== -1) type = 'video'
                formdata.append('file', data.file[0])
            }

            formdata.append('pageType', data.pageType)
            formdata.append('type', type)
            formdata.append('text', data.text ?? '')
            formdata.append('account_id', `${data.account_id}`)
            formdata.append('pageId', data.pageId)
            formdata.append('creation_date', data.creation_date)
            formdata.append('publication_date', data.publication_date)

            const response = (
                await axios.post(`${this.url}/sheduled/create`, formdata, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            console.log(response)

            return response.success
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getAllSheduled = async () => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/sheduled`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getSheduled = async (page_id: string | number) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/sheduled/${page_id}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getSheduledById = async (id: string | number) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/sheduled/post/${id}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public Cancel = () => {
        this.source.cancel()
    }
}
