import React, { useState, MouseEvent } from 'react'
import {
    FormControl,
    FormGroup,
    TextField,
    FilledInput,
    InputAdornment,
    IconButton,
    Button,
    Alert,
    Snackbar,
    Box
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Squares } from 'react-activity'
import { verifyEmail, verifyPassword } from '../../services/factory/OAuth'
import ButtonBackground from '../../assets/images/buttonBackground.png'
import OAuth from '../../services/store/Auth'

const ErrorMaps = new Map()

export default () => {
    const { innerWidth, innerHeight } = window
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [hasError, setError] = useState(false)
    const [reload, setReload] = useState(false)

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
    }

    const checkInput = () => {
        setError(false)
        ErrorMaps.clear()
        if (!verifyEmail(email)) ErrorMaps.set('Email', `Saisissez un email valide s'il vous plait`)
        if (!verifyPassword(password))
            ErrorMaps.set(
                'Password',
                `Votre mot de passe doit contenir au moins 8 caractères avec un Majuscule, une Minuscule et un nombre`
            )
    }

    const reinitilized = () => {
        setEmail('')
        setPassword('')
    }

    const onLogin = async () => {
        const API = new OAuth()
        checkInput()
        setReload(true)
        let timeout: any
        if (ErrorMaps.size === 0) {
            const data = {
                email,
                password
            }
            const response = await API.Signin(data)
            console.log(response)
            if (!response.canceled) {
                const expireIn = moment().add('1d').unix()
                localStorage.setItem('expireIn', `${expireIn}`)
                localStorage.setItem('refresh_token', response.refreshToken)
                localStorage.setItem('token', response.token)
                reinitilized()
            } else {
                ErrorMaps.set('Auth', `Email ou mot de passe incorrecte`)
                setError(true)
            }
            const token = localStorage.getItem('token')
            if (token && token !== '') {
                ;(async () => {
                    const result = await API.verifyToken()
                    if (!result.canceled) {
                        localStorage.setItem('id', result.id)
                        localStorage.setItem('name', `${result.prenom} ${result.nom}`)
                        navigate('/account')
                    }
                })()
            }
            timeout = setTimeout(() => {
                setReload(false)
                clearTimeout(timeout)
            }, 1000)
        } else {
            timeout = setTimeout(() => {
                setReload(false)
                clearTimeout(timeout)
            }, 1000)
            setError(true)
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                minWidth: innerWidth / 2 - 250,
                maxWidth: innerWidth / 2 - 250,
                minHeight: innerHeight / 2 - 100,
                maxHeight: innerHeight / 2 - 100
            }}>
            <form>
                <FormGroup className="group">
                    <FormControl>
                        <TextField
                            type="text"
                            variant="outlined"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            required
                            size="small"
                            style={{
                                minWidth: innerWidth / 2 - 250,
                                maxWidth: innerWidth / 2 - 250,
                                backgroundColor: '#FFF'
                            }}
                            error={hasError ? ErrorMaps.has('Email') : false}
                            helperText={hasError ? ErrorMaps.get('Email') : ''}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup className="group">
                    <FormControl>
                        <FilledInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            placeholder="Mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            style={{
                                minWidth: innerWidth / 2 - 250,
                                maxWidth: innerWidth / 2 - 250,
                                backgroundColor: '#FFF'
                            }}
                            error={hasError ? ErrorMaps.has('Password') : false}
                        />
                    </FormControl>
                </FormGroup>
            </form>
            <div>
                <Button variant="text" color="info">
                    Mot de passe oublié
                </Button>
            </div>

            {reload ? (
                <Box
                    sx={{
                        minWidth: '308px',
                        maxWidth: '308px',
                        minHeight: '58px',
                        maxHeight: '58px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <Squares size={40} animating={reload} color="#1B2444" />
                </Box>
            ) : (
                <button
                    type="button"
                    onClick={onLogin}
                    style={{
                        minWidth: '308px',
                        maxWidth: '308px',
                        minHeight: '58px',
                        maxHeight: '58px',
                        backgroundImage: `url(${ButtonBackground})`,
                        border: 'none',
                        fontSize: '20px',
                        color: '#FFFFFF',
                        fontWeight: 'bold'
                    }}>
                    Connexion
                </button>
            )}

            <Snackbar open={hasError ? ErrorMaps.has('Auth') : false}>
                <Alert severity="error">{hasError ? ErrorMaps.get('Auth') : ''}</Alert>
            </Snackbar>
        </div>
    )
}
