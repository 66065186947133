import { createTheme, PaletteOptions } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'

const MyPalette: PaletteOptions = {
    primary: {
        light: '#FFFFFF',
        dark: '#FFFFFF',
        main: '#FFFFFF',
        contrastText: '#171919'
    },
    secondary: {
        light: '#DFE9ED',
        dark: '#EBF3F7',
        main: '#DFE9ED',
        contrastText: '#171919'
    },
    error: {
        light: '#ef5350',
        dark: '#c62828',
        main: '#d32f2f',
        contrastText: '#FFFFFF'
    },
    warning: {
        light: '#ff9800',
        dark: '#e65100',
        main: '#ed6c02',
        contrastText: '#FFFFFF'
    },
    info: {
        light: '#136DBF',
        dark: '#136DBF',
        main: '#136DBF',
        contrastText: '#FFFFFF'
    },
    success: {
        light: '#4caf50',
        dark: '#1b5e20',
        main: '#2e7d32',
        contrastText: '#FFFFFF'
    }
}

/** const MyDarkPalette: PaletteOptions = {
    primary: {
        light: '#1A1C1E',
        dark: '#1A1C1E',
        main: '#1A1C1E',
        contrastText: '#171919'
    },
    secondary: {
        light: '#101314',
        dark: '#101314',
        main: '#101314',
        contrastText: '#171919'
    },
    error: {
        light: '#ef5350',
        dark: '#c62828',
        main: '#d32f2f',
        contrastText: '#FFFFFF'
    },
    warning: {
        light: '#ff9800',
        dark: '#e65100',
        main: '#ed6c02',
        contrastText: '#FFFFFF'
    },
    info: {
        light: '#03a9f4',
        dark: '#01579b',
        main: '#0288d1',
        contrastText: '#FFFFFF'
    },
    success: {
        light: '#4caf50',
        dark: '#1b5e20',
        main: '#2e7d32',
        contrastText: '#FFFFFF'
    }
} */

export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                  // palette values for light mode
                  ...MyPalette,
                  divider: '#fff',
                  action: {
                      active: 'rgba(0, 0, 0, 0.54)',
                      hover: 'rgba(0, 0, 0, 0.04)',
                      selected: 'rgba(0, 0, 0, 0.08)',
                      disabled: 'rgba(0, 0, 0, 0.26)',
                      disabledBackground: 'rgba(0, 0, 0, 0.12)'
                  },
                  text: {
                      primary: 'rgba(0, 0, 0, 0.87)',
                      secondary: 'rgba(0, 0, 0, 0.6)'
                  },
                  background: {
                      default: '#DFE9ED',
                      paper: '#DFE9ED'
                  }
              }
            : {
                  // palette values for dark mode
                  ...MyPalette,
                  divider: 'rgba(255, 255, 255, 0.12)',
                  action: {
                      active: '#fff',
                      hover: 'rgba(255, 255, 255, 0.08)',
                      selected: 'rgba(255, 255, 255, 0.16)',
                      disabled: 'rgba(255, 255, 255, 0.3)',
                      disabledBackground: 'rgba(255, 255, 255, 0.12)'
                  },
                  background: {
                      default: '#121212',
                      paper: '#121212'
                  },
                  text: {
                      primary: '#fff',
                      secondary: 'rgba(255, 255, 255, 0.7)'
                  }
              })
    }
})

export const theme = createTheme({
    palette: MyPalette
})
