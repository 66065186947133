/* eslint-disable spaced-comment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, useTheme, Typography } from '@mui/material'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'

interface IProps {
    file: Array<File>
    setFile: any
}

export default ({ file, setFile }: IProps) => {
    const theme = useTheme()
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone()

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            console.log(acceptedFiles)
            setFile(acceptedFiles)
            console.log('file state:', file)
        }
    }, [acceptedFiles])

    return (
        <Box
            sx={{
                minHeight: 140,
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 5,
                backgroundColor: theme.palette.secondary.main
            }}
            {...getRootProps()}>
            <input {...getInputProps()} />
            <AddPhotoAlternateIcon />
            {isDragActive ? (
                <Typography>Drop the files here ...</Typography>
            ) : (
                <Typography>JPEG, PNG, MP4 up to 100 MB</Typography>
            )}
        </Box>
    )
}
