import React, { useState, useEffect } from 'react'
import { Box, Button, Typography, InputBase, useTheme } from '@mui/material'
import { Add } from '@mui/icons-material'
import { AlertColor } from '@mui/material/Alert'
import YoutubeStore from '../../services/store/Youtube'
import Snack from '../sous-components/Snack'

interface IProps {
    account: any
}

export default ({ account }: IProps) => {
    const theme = useTheme()
    const [title, setTitle] = useState('')
    const [access, setAccess] = useState('')
    const [message, setMessage] = useState('')
    const [type, setType] = useState<AlertColor>('success')
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const accessData = {
            id: account.id,
            pageId: account.pageId,
            type: account.type
        }
        const encoded = btoa(JSON.stringify(accessData))
        setAccess(encoded)
        console.log(access)
    }, [])

    const handleClose = () => {
        setOpen(false)
    }

    const onCreate = async () => {
        try {
            const API = new YoutubeStore()
            const response = await API.createPlaylist(title, access)
            if (!response.canceled && response) {
                setMessage('Playlist créée avec succès !')
                setType('success')
                setTitle('')
                setOpen(true)
            } else {
                setMessage('Une erreur est survenue !')
                setType('error')
                setOpen(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                minWidth: '250px',
                maxWidth: '250px',
                minHeight: '175px',
                maxHeight: '175px',
                borderRadius: 2,
                backgroundColor: theme.palette.primary.main,
                padding: 2
            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: '100%',
                    maxWidth: '100%',
                    minHeight: '40%',
                    maxHeight: '40%'
                }}>
                <Typography variant="h6">Création de playlist</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    minWidth: '100%',
                    maxWidth: '100%',
                    minHeight: '60%',
                    maxHeight: '60%'
                }}>
                <InputBase
                    value={title}
                    onChange={(e) => setTitle(e.currentTarget.value)}
                    placeholder="Titre"
                    sx={{
                        padding: 1,
                        minWidth: '50%',
                        border: 'none',
                        backgroundColor: theme.palette.secondary.main,
                        marginBottom: '10px'
                    }}
                />
                <Button variant="contained" color="info" onClick={onCreate}>
                    Créer
                    <Add />
                </Button>
            </Box>
            <Snack message={message} open={open} type={type} handleClose={handleClose} />
        </Box>
    )
}
