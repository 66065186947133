/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { styled, alpha, Button, Menu, MenuProps, MenuItem, Divider } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SocialStore from '../../services/store/Social'
import Meta from '../../assets/images/meta.png'
import Youtube from '../../assets/images/youtube.png'
import Twitter from '../../assets/images/twitter.png'
import Pinterest from '../../assets/images/pinterest.png'

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                )
            }
        }
    }
}))

export default () => {
    const [meta, setMeta] = useState<string | null>(null)
    const [google, setGoogle] = useState<string | null>(null)
    const [twitter, setTwitter] = useState<string | null>(null)
    const [pinterest, setPinterest] = useState<string | null>(null)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (link: string) => {
        setAnchorEl(null)
        if (link) window.location.replace(link)
    }

    const handleCloseNoRedirect = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        const API = new SocialStore()
        let isSubscribed = true
        ;(async () => {
            if (isSubscribed) {
                const metaLink = await API.getMetaOauthLink()
                if (!metaLink.canceled) setMeta(metaLink)
                const googleLink = await API.getGoogleOauthLink()
                if (!googleLink.canceled) setGoogle(googleLink)
                const twitterLink = await API.getTwitterOauthLink()
                if (!twitterLink.canceled) setTwitter(twitterLink)
                const pinterestLink = await API.getPinterestOauthLink()
                if (!pinterestLink.canceled) setPinterest(pinterestLink)
                console.log(metaLink, googleLink, twitterLink, pinterestLink)
            }
        })()

        return () => {
            API.Cancel()
            isSubscribed = false
        }
    }, [])

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                color="info"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}>
                Ajouter des comptes
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseNoRedirect}>
                {meta ? (
                    <MenuItem href={meta} onClick={() => handleClose(meta)} disableRipple>
                        <img src={Meta} alt="Meta" height={25} width={25} />
                        Meta
                    </MenuItem>
                ) : null}
                {google ? (
                    <MenuItem href={google} onClick={() => handleClose(google)} disableRipple>
                        <img src={Youtube} alt="Youtube" height={25} width={25} />
                        Youtube
                    </MenuItem>
                ) : null}
                <Divider sx={{ my: 0.5 }} />
                {twitter ? (
                    <MenuItem href={twitter} onClick={() => handleClose(twitter)} disableRipple>
                        <img src={Twitter} alt="Twitter" height={25} width={25} />
                        Twitter
                    </MenuItem>
                ) : null}
                {pinterest ? (
                    <MenuItem href={pinterest} onClick={() => handleClose(pinterest)} disableRipple>
                        <img src={Pinterest} alt="Pinterest" height={25} width={25} />
                        Pinterest
                    </MenuItem>
                ) : null}
            </StyledMenu>
        </div>
    )
}
