/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, MouseEvent } from 'react'
import {
    FormGroup,
    FormControl,
    InputAdornment,
    TextField,
    IconButton,
    InputLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Checkbox,
    Box
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FilledInput from '@mui/material/FilledInput'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Squares } from 'react-activity'
import { verifyName, verifyEmail, verifyPassword, verifyDate } from '../../services/factory/OAuth'
import Snack from '../sous-components/Snack'
import ButtonBackground from '../../assets/images/buttonBackground.png'
import OAuth from '../../services/store/Auth'

const ErrorMaps = new Map()

export default () => {
    const { innerWidth } = window
    const navigate = useNavigate()
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [date, setDate] = useState<string | null>('')
    const [sexe, setSexe] = useState('masculin')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [checked, setChecked] = useState(false)
    const [hasError, setError] = useState(false)
    const [reload, setReload] = useState(false)
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
    }

    const handleClickShowConfirm = () => setShowConfirm((show) => !show)

    const handleMouseDownConfirm = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
    }

    const checkInput = () => {
        setError(false)
        ErrorMaps.clear()
        if (!verifyName(nom)) ErrorMaps.set('Nom', `Saisissez un nom valide s'il vous plait`)
        if (!verifyName(prenom))
            ErrorMaps.set('Prenom', `Saisissez un prénom valide s'il vous plait`)
        if (!verifyEmail(email)) ErrorMaps.set('Email', `Saisissez un email valide s'il vous plait`)
        if (!verifyPassword(password))
            ErrorMaps.set(
                'Password',
                `Votre mot de passe doit contenir au moins 8 caractères avec un Majuscule, une Minuscule et un nombre`
            )
        if (password !== confirm)
            ErrorMaps.set('Confirmation', `Veuillez bien confirmer votre mot de passe`)
        if (!checked)
            ErrorMaps.set('Verification', `Veuillez confimer que vous êtes bien un humain`)
        if (!date || !verifyDate(date))
            ErrorMaps.set('Date', `Veuillez saisir votre date de naissance`)
    }

    const reinitilized = () => {
        setNom('')
        setPrenom('')
        setEmail('')
        setPassword('')
        setConfirm('')
        setDate('')
        setChecked(false)
    }

    const onSignup = async () => {
        checkInput()
        setReload(true)
        let timeout: any
        if (ErrorMaps.size === 0) {
            const API = new OAuth()
            const data = {
                nom,
                prenom,
                email,
                password,
                birth: moment(date).format('DD-MM-YYYY'),
                sexe,
                role: 'admin'
            }
            const response = await API.Signup(data)
            if (!response.canceled) {
                const expireIn = moment().add('1d').unix()
                localStorage.setItem('expireIn', `${expireIn}`)
                localStorage.setItem('refresh_token', response.refreshToken)
                localStorage.setItem('token', response.token)
                reinitilized()
            } else {
                setOpen(true)
                setMessage(`Une erreur est survenu lors de l'inscription`)
                const timeout2 = setTimeout(() => {
                    setOpen(false)
                    setMessage(``)
                    clearTimeout(timeout2)
                }, 3000)
            }
            const token = localStorage.getItem('token')
            if (token && token !== '') {
                ;(async () => {
                    const result = await API.verifyToken()
                    if (!result.canceled) {
                        localStorage.setItem('id', result.id)
                        localStorage.setItem('name', `${result.prenom} ${result.nom}`)
                        navigate('/account')
                    }
                })()
            }
            timeout = setTimeout(() => {
                setReload(false)
                clearTimeout(timeout)
            }, 1000)
        } else {
            setError(true)
            timeout = setTimeout(() => {
                setReload(false)
                clearTimeout(timeout)
            }, 1000)
        }
    }

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minWidth: innerWidth / 2 - 250,
                maxWidth: innerWidth / 2 - 250
            }}>
            <form>
                <FormGroup id="name" className="group">
                    <FormControl>
                        <TextField
                            type="text"
                            variant="outlined"
                            placeholder="Nom"
                            value={nom}
                            onChange={(e) => setNom(e.currentTarget.value)}
                            required
                            className="name input"
                            size="small"
                            style={{
                                minWidth: (innerWidth / 2 - 250) / 2 - 10,
                                maxWidth: (innerWidth / 2 - 250) / 2 - 10,
                                backgroundColor: '#FFF'
                            }}
                            error={hasError ? ErrorMaps.has('Nom') : false}
                            helperText={hasError ? ErrorMaps.get('Nom') : ''}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            type="text"
                            variant="outlined"
                            placeholder="Prénom"
                            value={prenom}
                            onChange={(e) => setPrenom(e.currentTarget.value)}
                            required
                            className="name input"
                            size="small"
                            style={{
                                minWidth: (innerWidth / 2 - 250) / 2 - 10,
                                maxWidth: (innerWidth / 2 - 250) / 2 - 10,
                                backgroundColor: '#FFF'
                            }}
                            error={hasError ? ErrorMaps.has('Prenom') : false}
                            helperText={hasError ? ErrorMaps.get('Prenom') : ''}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup className="group">
                    <FormControl>
                        <TextField
                            type="text"
                            variant="outlined"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            required
                            className="input"
                            size="small"
                            style={{ backgroundColor: '#FFF' }}
                            error={hasError ? ErrorMaps.has('Email') : false}
                            helperText={hasError ? ErrorMaps.get('Email') : ''}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup className="group">
                    <FormControl>
                        <FilledInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            placeholder="Mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            className="input"
                            size="small"
                            style={{ backgroundColor: '#FFF' }}
                            error={hasError ? ErrorMaps.has('Password') : false}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup className="group">
                    <FormControl>
                        <FilledInput
                            id="outlined-adornment-password"
                            type={showConfirm ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirm}
                                        onMouseDown={handleMouseDownConfirm}
                                        edge="end">
                                        {showConfirm ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            placeholder="Confirmer"
                            value={confirm}
                            onChange={(e) => setConfirm(e.currentTarget.value)}
                            className="input"
                            size="small"
                            style={{ backgroundColor: '#FFF' }}
                            error={hasError ? ErrorMaps.has('Confirmation') : false}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup id="birth">
                    <InputLabel>Date de naissance</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            mask="__/__/____"
                            value={date}
                            onChange={(newValue) => setDate(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    style={{
                                        minWidth: (innerWidth / 2 - 250) / 2 - 10,
                                        maxWidth: (innerWidth / 2 - 250) / 2 - 10,
                                        marginLeft: 10,
                                        backgroundColor: '#FFF'
                                    }}
                                    error={hasError ? ErrorMaps.has('Date') : false}
                                    helperText={hasError ? ErrorMaps.get('Date') : ''}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </FormGroup>
                <FormGroup className="group check">
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue="masculin">
                            <FormControlLabel
                                value="masculin"
                                onSelect={() => setSexe('masculin')}
                                control={<Radio size="small" />}
                                label="Homme"
                            />
                            <FormControlLabel
                                value="feminin"
                                onSelect={() => setSexe('feminin')}
                                control={<Radio size="small" />}
                                label="Femme"
                            />
                            <FormControlLabel
                                value="personnalise"
                                onSelect={() => setSexe('other')}
                                control={<Radio size="small" />}
                                label="personnalise"
                            />
                        </RadioGroup>
                    </FormControl>
                </FormGroup>
                <FormGroup className="group check">
                    <FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={() => setChecked(!checked)}
                                    size="small"
                                />
                            }
                            label="Je suis un humain"
                        />
                    </FormControl>
                </FormGroup>
            </form>
            {reload ? (
                <Box
                    sx={{
                        minWidth: '308px',
                        maxWidth: '308px',
                        minHeight: '58px',
                        maxHeight: '58px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <Squares size={40} animating={reload} color="#1B2444" />
                </Box>
            ) : (
                <button
                    type="button"
                    onClick={onSignup}
                    style={{
                        minWidth: '308px',
                        maxWidth: '308px',
                        minHeight: '58px',
                        maxHeight: '58px',
                        backgroundImage: `url(${ButtonBackground})`,
                        border: 'none',
                        fontSize: '20px',
                        color: '#FFFFFF',
                        fontWeight: 'bold'
                    }}>
                    Inscription
                </button>
            )}
            <Snack
                open={open}
                message={message}
                handleClose={() => setOpen(false)}
                type={hasError ? 'error' : 'success'}
            />
        </Box>
    )
}
