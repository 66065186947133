import axios from 'axios'
import Store from './Store'

export default class SocialStore extends Store {
    private source = axios.CancelToken.source()

    public getState = () => {
        const id = localStorage.getItem('id')
        if (!id || id === '') return undefined

        const data = {
            id,
            url: `http://localhost:3000/account`
        }

        return btoa(JSON.stringify(data))
    }

    public getMetaOauthLink = async () => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const state = this.getState()
            if (!state) return { canceled: true, message: 'no state' }

            const response = (
                await axios.get(`${this.url}/meta/oauthlink?state=${state}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            console.log(response)

            if (response.success) return response.link

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getGoogleOauthLink = async () => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const state = this.getState()
            if (!state) return { canceled: true, message: 'no state' }

            const response = (
                await axios.get(`${this.url}/google/oauthlink?state=${state}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            console.log(response)

            if (response.success) return response.link

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getTwitterOauthLink = async () => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const state = this.getState()
            if (!state) return { canceled: true, message: 'no state' }

            const response = (
                await axios.get(`${this.url}/twitter/oauthlink?state=${state}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            console.log(response)

            if (response.success) return response.link

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getPinterestOauthLink = async () => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const state = this.getState()
            if (!state) return { canceled: true, message: 'no state' }

            const response = (
                await axios.get(`${this.url}/pinterest/oauthlink?state=${state}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            console.log(response)

            if (response.success) return response.link

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public Cancel = () => {
        this.source.cancel()
    }
}
