/* eslint-disable no-restricted-syntax */
import axios from 'axios'
import Store from './Store'

export default class YoutubeStore extends Store {
    private source = axios.CancelToken.source()

    public getPlaylist = async (access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/youtube/playlist/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public createPlaylist = async (title: string, access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const formdata = new FormData()
            formdata.append('title', title)

            const response = (
                await axios.post(`${this.url}/youtube/playlist/create/${access}`, formdata, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            return response.success
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public createPost = async (data: any, access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const formdata = new FormData()
            formdata.append('description', data.text)
            formdata.append('title', data.title)
            formdata.append('playlist', data.playlist)
            if (data.file && data.file.length > 0) {
                formdata.append('file', data.file[0])
            }

            const response = (
                await axios.post(`${this.url}/youtube/video/create/${access}`, formdata, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getMyPosts = async (access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/youtube/videos/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getPost = async (videoId: string, access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/youtube/video/${videoId}/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getStats = async (access: string) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/youtube/stats/${access}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public Cancel = () => {
        this.source.cancel()
    }
}
