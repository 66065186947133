import React, { useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import MetricLoading from './MetricLoading'
import Youtube from './Youtube'
import Twitter from './Twitter'
import Pinterest from './Pinterest'
import Facebook from './Facebook'
import Instagram from './Instagram'
/** import TwitterStore from '../../services/store/Twitter'
import PinterestStore from '../../services/store/Pinterest'
import FacebookStore from '../../services/store/Facebook'
import InstagramStore from '../../services/store/Instagram' 
*/

interface IProps {
    account: any
}

export default ({ account }: IProps) => {
    const Theme = useTheme()
    const [reload, setReload] = useState(false)

    useEffect(() => {
        console.log(account)
        setReload(true)
        const timer = setTimeout(() => {
            setReload(false)
        }, 2000)
        /** ;(async () => {
            await onLoadStat()
        })() */

        return () => {
            clearTimeout(timer)
        }
    }, [account])

    const renderer = () => {
        if (account) {
            if (account.type === 'youtube') return <Youtube account={account} />
            if (account.type === 'twitter') return <Twitter account={account} />
            if (account.type === 'pinterest') return <Pinterest account={account} />
            if (account.type === 'facebook') return <Facebook account={account} />
            if (account.type === 'instagram') return <Instagram account={account} />
            return null
        }
        return null
    }

    /** const onLoadStat = async () => {
        if (account) {
            const accountInfo = {
                id: account.id,
                type: account.type,
                pageId: account.pageId
            }
            const encodedAccess = btoa(JSON.stringify(accountInfo))
            if (account.type === 'twitter') {
                const API = new TwitterStore()
                await API.getStats(encodedAccess)
            }
            if (account.type === 'pinterest') {
                const API = new PinterestStore()
                await API.getStats(encodedAccess)
            }
            if (account.type === 'facebook') {
                const API = new FacebookStore()
                await API.getStats(encodedAccess)
            }
            if (account.type === 'instagram') {
                const API = new InstagramStore()
                await API.getStats(encodedAccess)
            }
        }
    } */

    if (reload) return <MetricLoading />

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                minHeight: '100%',
                minWidth: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
                backgroundColor: Theme.palette.primary.main,
                borderRadius: '10px'
            }}>
            {renderer()}
        </Box>
    )
}
