import React, { useEffect, useState } from 'react'
import {
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    Avatar,
    IconButton,
    Typography,
    useTheme
} from '@mui/material'
import { red } from '@mui/material/colors'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import Facebook from '../../assets/images/facebook.png'
import Instagram from '../../assets/images/instagram.png'
import Youtube from '../../assets/images/youtube.png'
import Twitter from '../../assets/images/twitter.png'
import Pinterest from '../../assets/images/pinterest.png'
import NoImage from '../../assets/images/noimage.jpeg'
import SheduledStore from '../../services/store/Sheduled'

interface IData {
    accountType: string
    accountName: string
    description: string
    date: string
    file: string | null
}

interface IProps {
    selected: any
    onClose: any
}

export default ({ selected, onClose }: IProps) => {
    const Theme = useTheme()
    const [data, setData] = useState<IData | null>(null)
    const [media, setMedia] = useState<any>(null)
    const [reload, setReload] = useState(false)

    useEffect(() => {
        const API = new SheduledStore()
        let isSubscribed = true
        setReload(true)
        ;(async () => {
            if (isSubscribed) {
                const response = await API.getSheduledById(selected.id)
                if (!response.canceled) {
                    setData(response)
                    setMedia(renderMedia(response.accountType))
                }
                setReload(false)
            }
        })()

        return () => {
            isSubscribed = false
            API.Cancel()
        }
    }, [selected])

    const renderMedia = (type: string) => {
        switch (type) {
            case 'facebook':
                return Facebook
            case 'instagram':
                return Instagram
            case 'youtube':
                return Youtube
            case 'twitter':
                return Twitter
            case 'pinterest':
                return Pinterest
            default:
                return Facebook
        }
    }

    if (!data || reload) return null

    return (
        <Card
            sx={{
                maxWidth: '40%',
                minWidth: '40%',
                minHeight: 200,
                backgroundColor: Theme.palette.primary.main
            }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        {media ? (
                            <img src={media} alt="avatar" style={{ height: 25, width: 25 }} />
                        ) : (
                            'P'
                        )}
                    </Avatar>
                }
                action={
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
                title={data.accountName}
                subheader={moment(data.date).calendar()}
            />
            <CardMedia
                component="img"
                height="194"
                image={data.file ? data.file : NoImage}
                alt="Image"
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {data.description}
                </Typography>
            </CardContent>
        </Card>
    )
}
