import React from 'react'
import { Box, FormGroup, FormControl, Typography, useTheme, InputBase } from '@mui/material'
import Dropzone from './Dropzone'

interface IProps {
    link: string
    setLink: any
    text: string
    setText: any
    file: Array<File>
    setFile: any
}

export default ({ link, setLink, text, setText, file, setFile }: IProps) => {
    const theme = useTheme()

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                minHeight: '100%',
                maxHeight: '100%'
            }}>
            <FormGroup>
                <FormControl>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                        Partager un lien
                    </Typography>
                    <InputBase
                        value={link}
                        onChange={(e) => setLink(e.currentTarget.value)}
                        placeholder="Lien"
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 5,
                            minWidth: '90%',
                            maxWidth: '90%',
                            padding: 1
                        }}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                        Decrivez votre post
                    </Typography>
                    <InputBase
                        multiline
                        minRows={3}
                        value={text}
                        onChange={(e) => setText(e.currentTarget.value)}
                        placeholder="Description"
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 5,
                            minWidth: '90%',
                            maxWidth: '90%',
                            padding: 2
                        }}
                    />
                </FormControl>
            </FormGroup>
            {link === '' ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '100%',
                        maxWidth: '100%',
                        minHeight: 160,
                        maxHeight: 160
                    }}>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                        Choisissez des photos ou des vidéo
                    </Typography>
                    <Dropzone file={file} setFile={setFile} />
                </Box>
            ) : null}
        </Box>
    )
}
