/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Box, Typography, LinearProgress } from '@mui/material'

export default () => (
    <Box
        sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100%',
            minWidth: '100%'
        }}>
        <Typography component="h4" variant="h4">
            Créer et programmer vos publications
        </Typography>
        <Typography paragraph>
            Depuis cet interface vous auriez le pouvoir de créer et de programmer vos postes.
        </Typography>
        <LinearProgress sx={{ width: '45%' }} color="info" />
    </Box>
)
