import React from 'react'
import { Box } from '@mui/material'
import PostLoading from '../sous-components/PostLoading'

const fake = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    }
]

export default () => (
    <Box
        sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '110%',
            minWidth: '100%',
            overflow: 'scroll'
        }}>
        {fake.map((item) => (
            <PostLoading key={`${item.id}`} />
        ))}
    </Box>
)
