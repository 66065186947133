import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography, useTheme } from '@mui/material'
import List from './Account/List'
import Snack from './sous-components/Snack'

interface IData {
    success: boolean
    message: string
}

export default () => {
    const theme = useTheme()
    const { message } = useParams()
    const { innerHeight } = window
    const [height, setHeight] = useState(innerHeight)
    const [data, setData] = useState<IData | null>(null)

    useEffect(() => {
        let timeout: any
        if (message) {
            const decoded = JSON.parse(atob(message))
            setData(decoded)

            timeout = setTimeout(() => {
                setData(null)
            }, 6000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [message])

    useEffect(() => {
        const onResize = () => {
            if (height !== window.innerHeight) {
                setHeight(window.innerHeight)
            }
        }

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return (
        <Box
            sx={{
                minWidth: '100%',
                minHeight: height - (height * 15) / 100,
                maxHeight: height - (height * 15) / 100,
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'row',
                boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.5)',
                borderRadius: 2,
                backgroundColor: theme.palette.primary.main
            }}>
            <Box
                sx={{
                    minWidth: '30%',
                    minHeight: '100%',
                    maxHeight: '100%',
                    maxWidth: '30%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRightWidth: '1px',
                    borderColor: '#C3C3C3'
                }}>
                <Typography variant="h5" textAlign="center">
                    Gérer vos comptes
                </Typography>
                <Typography paragraph textAlign="center">
                    {' '}
                    {`En ajoutant vos comptes ici vous donnez l'autorisation aux community manager de gérer le contenu de vos réseaux sociaux.`}{' '}
                </Typography>
            </Box>
            <Box
                sx={{
                    minWidth: '69%',
                    minHeight: '100%',
                    maxHeight: '100%',
                    maxWidth: '69%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <List />
            </Box>
            {data ? (
                <Snack
                    open={data !== null}
                    type={data.success ? 'success' : 'error'}
                    message={data.message}
                    handleClose={() => setData(null)}
                />
            ) : null}
        </Box>
    )
}
