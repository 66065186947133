import moment from 'moment'
import OAuth from '../store/Auth'

export const verifyName = (text: string) => {
    const regex = /^[a-zA-Z]{2,50}$/
    return regex.test(text)
}

export const verifyEmail = (text: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
    return regex.test(text)
}

export const verifyDate = (dateString: string) => {
    const date = new Date(dateString)
    return !Number.isNaN(date.getTime()) && dateString !== ''
}

export const verifyPassword = (text: string) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()-_=+{};:,<.>?~`[\]\\/]{8,}$/
    return regex.test(text)
}

export const verifyToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh_token')
        const expireIn = localStorage.getItem('expireIn')
        if (!refreshToken || !expireIn) return false

        const expire_In = moment(parseInt(expireIn, 10) * 1000)

        if (moment(expire_In).diff(moment(), 'd') <= 0) {
            const API = new OAuth()
            const response = await API.refreshToken(refreshToken)
            if (response.canceled) return false
            const new_expireIn = moment().add('1d').unix()
            localStorage.setItem('expireIn', `${new_expireIn}`)
            localStorage.setItem('token', response)
        }

        return true
    } catch (error) {
        console.log(error)

        return false
    }
}
