import axios from 'axios'
import Store from './Store'

export default class AccountStore extends Store {
    private source = axios.CancelToken.source()

    public getMyOwnAccounts = async () => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/account/own`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getMyManagedAccounts = async () => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/account/managed`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getMyAccounts = async () => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/account/own`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            if (response.success) return response.data

            return { canceled: true }
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public getManagerAccount = async (account_id: string | number) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.get(`${this.url}/account/manager/${account_id}`, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data
            if (!response.success) return { canceled: true }

            return response.data
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public addCommunity = async (user_id: string | number, account_id: string | number) => {
        try {
            const data = {
                user_id,
                account_id
            }

            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.post(`${this.url}/account/manager/add`, data, {
                    cancelToken: this.source.token,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ).data

            return response.success
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public removeCommunity = async (user_id: string | number, account_id: string | number) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) return { canceled: true, message: 'no token' }

            const response = (
                await axios.delete(
                    `${this.url}/account/manager/remove?user_id=${user_id}&account_id=${account_id}`,
                    {
                        cancelToken: this.source.token,
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
            ).data

            return response.success
        } catch (error) {
            if (axios.isCancel(error)) return { canceled: true }
            throw error
        }
    }

    public Cancel = () => {
        this.source.cancel()
    }
}
