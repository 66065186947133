export interface IPublishedFormated {
    id: string | number
    type: string
    pageId?: string | number
    accountId?: string | number
}

export const formatYoutubeResponse = (data: any): IPublishedFormated | null => {
    if (
        data &&
        data.snippet &&
        data.snippet.resourceId &&
        data.snippet.channelId &&
        data.snippet.resourceId.videoId
    ) {
        const { videoId } = data.snippet.resourceId
        const info = {
            id: videoId,
            type: 'youtube'
        }

        return info
    }

    return null
}

export const formatTweetResponse = (data: any): IPublishedFormated | null => {
    if (data && data.id)
        return {
            id: data.id,
            type: 'twitter'
        }
    return null
}

export const formatPinResponse = (data: any): IPublishedFormated | null => {
    if (data && data.id)
        return {
            id: data.id,
            type: 'pinterest'
        }

    return null
}

export const formatFacebookResponse = (data: any): IPublishedFormated | null => {
    if (data && data.id)
        return {
            id: data.post_id,
            type: 'facebook'
        }

    return null
}

export const formatInstagramResponse = (data: any): IPublishedFormated | null => {
    if (data && data.id)
        return {
            id: data.id,
            type: 'instagram'
        }

    return null
}
