import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    Typography,
    InputBase,
    useTheme,
    Divider,
    Select,
    MenuItem
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { AlertColor } from '@mui/material/Alert'
import PinterestStore from '../../services/store/Pinterest'
import Snack from '../sous-components/Snack'

interface IProps {
    account: any
}

export default ({ account }: IProps) => {
    const theme = useTheme()
    const [board, setBoard] = useState('')
    const [description, setDescription] = useState('')
    const [privacy, setPrivacy] = useState('PUBLIC')
    const [section, setSection] = useState('')
    const [boardId, setBoardId] = useState('')
    const [data, setData] = useState<Array<any>>([])
    const [access, setAccess] = useState('')
    const [message, setMessage] = useState('')
    const [type, setType] = useState<AlertColor>('success')
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const accessData = {
            id: account.id,
            pageId: account.pageId,
            type: account.type
        }
        const encoded = btoa(JSON.stringify(accessData))
        setAccess(encoded)
        console.log(access)
        const API = new PinterestStore()
        ;(async () => {
            const response = await API.getBoard(encoded)
            console.log('pinresponse: ', response)
            if (!response.canceled && Array.isArray(response.items) && response.items.length > 0) {
                setData(response.items)
                setBoardId(response.items[0].id)
            }
        })()
    }, [])

    const handleClose = () => {
        setOpen(false)
    }

    const onCreateBoard = async () => {
        try {
            const API = new PinterestStore()
            const boardData = {
                name: board,
                description,
                privacy
            }
            const response = await API.createBoard(boardData, access)
            if (!response.canceled && response) {
                setMessage('Board créée avec succès !')
                setType('success')
                setBoard('')
                setDescription('')
                setOpen(true)
            } else {
                setMessage('Une erreur est survenue !')
                setType('error')
                setOpen(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onCreateSection = async () => {
        try {
            const API = new PinterestStore()
            const sectionData = {
                name: section,
                board_id: boardId
            }
            const response = await API.createSection(sectionData, access)
            if (!response.canceled && response) {
                setMessage('Board créée avec succès !')
                setType('success')
                setSection('')
                setOpen(true)
            } else {
                setMessage('Une erreur est survenue !')
                setType('error')
                setOpen(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                minWidth: '250px',
                maxWidth: '250px',
                minHeight: '600px',
                maxHeight: '600px',
                borderRadius: 2,
                backgroundColor: theme.palette.primary.main,
                padding: 2
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '100%',
                    maxWidth: '100%',
                    minHeight: '67%',
                    maxHeight: '67%'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: '100%',
                        maxWidth: '100%',
                        minHeight: '10%',
                        maxHeight: '10%'
                    }}>
                    <Typography variant="h6">Création de board</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        minWidth: '100%',
                        maxWidth: '100%',
                        minHeight: '90%',
                        maxHeight: '90%'
                    }}>
                    <InputBase
                        value={board}
                        onChange={(e) => setBoard(e.currentTarget.value)}
                        placeholder="Board"
                        sx={{
                            padding: 1,
                            minWidth: '50%',
                            border: 'none',
                            backgroundColor: theme.palette.secondary.main,
                            marginBottom: '10px'
                        }}
                    />
                    <InputBase
                        value={description}
                        multiline
                        minRows={3}
                        onChange={(e) => setDescription(e.currentTarget.value)}
                        placeholder="Description"
                        sx={{
                            padding: 1,
                            minWidth: '50%',
                            border: 'none',
                            backgroundColor: theme.palette.secondary.main,
                            marginBottom: '10px'
                        }}
                    />
                    <Select
                        value={privacy}
                        onChange={(e) => setPrivacy(e.target.value)}
                        displayEmpty
                        size="small"
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                            minWidth: '50%',
                            backgroundColor: theme.palette.secondary.main,
                            marginBottom: '10px'
                        }}>
                        <MenuItem value="PUBLIC">PUBLIC</MenuItem>
                        <MenuItem value="PROTECTED">PROTECTED</MenuItem>
                        <MenuItem value="SECRET">SECRET</MenuItem>
                    </Select>
                    <Button variant="contained" color="info" onClick={onCreateBoard}>
                        Créer une board
                        <Add />
                    </Button>
                </Box>
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '100%',
                    maxWidth: '100%',
                    minHeight: '30%',
                    maxHeight: '30%'
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: '100%',
                        maxWidth: '100%',
                        minHeight: '10%',
                        maxHeight: '10%'
                    }}>
                    <Typography variant="h6">Création de section</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        minWidth: '100%',
                        maxWidth: '100%',
                        minHeight: '90%',
                        maxHeight: '90%'
                    }}>
                    <Select
                        value={boardId}
                        onChange={(e) => setBoardId(e.target.value)}
                        displayEmpty
                        size="small"
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                            minWidth: '50%',
                            backgroundColor: theme.palette.secondary.main,
                            marginBottom: '10px'
                        }}>
                        {data.length === 0 ? (
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                        ) : null}
                        {data.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <InputBase
                        value={section}
                        onChange={(e) => setSection(e.currentTarget.value)}
                        placeholder="Section"
                        sx={{
                            padding: 1,
                            minWidth: '50%',
                            border: 'none',
                            backgroundColor: theme.palette.secondary.main,
                            marginBottom: '10px'
                        }}
                    />
                    <Button variant="contained" color="info" onClick={onCreateSection}>
                        Créer une section
                        <Add />
                    </Button>
                </Box>
            </Box>
            <Snack message={message} open={open} type={type} handleClose={handleClose} />
        </Box>
    )
}
