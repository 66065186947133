import React, { useState, useEffect } from 'react'
import {
    Box,
    FormGroup,
    FormControl,
    Typography,
    useTheme,
    InputBase,
    Select,
    MenuItem
} from '@mui/material'
import Dropzone from './Dropzone'
import PinterestStore from '../../services/store/Pinterest'

interface IProps {
    access: string
    alt_text: string
    setAlt_text: any
    link: string
    setLink: any
    title: any
    setTitle: any
    board: string
    setBoard: any
    section: string
    setSection: any
    text: string
    setText: any
    file: Array<any>
    setFile: any
}

export default ({
    access,
    text,
    setText,
    file,
    setFile,
    board,
    setBoard,
    section,
    setSection,
    title,
    setTitle,
    link,
    setLink,
    alt_text,
    setAlt_text
}: IProps) => {
    const theme = useTheme()
    const [boardList, setBoardList] = useState<Array<any>>([])
    const [sectionList, setSectionList] = useState<Array<any>>([])

    useEffect(() => {
        // recupération des board
        const API = new PinterestStore()
        ;(async () => {
            const response = await API.getBoard(access)
            if (!response.canceled && Array.isArray(response.items) && response.items.length > 0) {
                setBoardList(response.items)
                setBoard(response.items[0].id)
            }
        })()

        return () => {
            API.Cancel()
        }
    }, [])

    useEffect(() => {
        // recupération des sections
        const API = new PinterestStore()
        ;(async () => {
            if (board !== '') {
                const response = await API.getSection(board, access)
                if (
                    !response.canceled &&
                    Array.isArray(response.items) &&
                    response.items.length > 0
                ) {
                    setSectionList(response.items)
                    setSection(response.items[0].id)
                }
            }
        })()

        return () => {
            API.Cancel()
        }
    }, [board])

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                minHeight: '90%',
                maxHeight: '90%',
                overflow: 'scroll'
            }}>
            <FormGroup>
                <FormControl>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                        Sélectionnez le tableau:
                    </Typography>
                    <Select
                        value={board}
                        onChange={(e) => setBoard(e.target.value)}
                        displayEmpty
                        size="small"
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 5,
                            minWidth: '90%',
                            maxWidth: '90%',
                            padding: 2
                        }}>
                        {boardList.length === 0 ? (
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                        ) : null}
                        {boardList.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                        Sélectionnez la section:
                    </Typography>
                    <Select
                        value={section}
                        onChange={(e) => setSection(e.target.value)}
                        displayEmpty
                        size="small"
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 5,
                            minWidth: '90%',
                            maxWidth: '90%',
                            padding: 2
                        }}>
                        {sectionList.length === 0 ? (
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                        ) : null}
                        {sectionList.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>Titre du post :</Typography>
                    <InputBase
                        value={title}
                        onChange={(e) => setTitle(e.currentTarget.value)}
                        placeholder="Titre"
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 5,
                            minWidth: '90%',
                            maxWidth: '90%',
                            padding: 2
                        }}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>Lien :</Typography>
                    <InputBase
                        value={link}
                        onChange={(e) => setLink(e.currentTarget.value)}
                        placeholder="Lien"
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 5,
                            minWidth: '90%',
                            maxWidth: '90%',
                            padding: 2
                        }}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                        Alternative image :
                    </Typography>
                    <InputBase
                        value={alt_text}
                        onChange={(e) => setAlt_text(e.currentTarget.value)}
                        placeholder="Alt text"
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 5,
                            minWidth: '90%',
                            maxWidth: '90%',
                            padding: 2
                        }}
                    />
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormControl>
                    <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                        Decrivez votre post
                    </Typography>
                    <InputBase
                        multiline
                        minRows={3}
                        value={text}
                        onChange={(e) => setText(e.currentTarget.value)}
                        placeholder="Description"
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: 5,
                            minWidth: '90%',
                            maxWidth: '90%',
                            padding: 2
                        }}
                    />
                </FormControl>
            </FormGroup>
            <Typography sx={{ color: '#7B8C95', fontSize: 12 }}>
                Choisissez des photos ou des vidéo
            </Typography>
            <Dropzone file={file} setFile={setFile} />
        </Box>
    )
}
