import React from 'react'
import { Box, useTheme } from '@mui/material'
import LogosAnimation from './LogosAnimation'

export default () => {
    const Theme = useTheme()
    const { innerWidth, innerHeight } = window

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                minWidth: innerWidth,
                minHeight: innerHeight,
                maxWidth: innerWidth,
                maxHeight: innerHeight,
                backgroundColor: Theme.palette.primary.main
            }}>
            <LogosAnimation height={innerHeight / 2} width={innerWidth / 2} animating />
        </Box>
    )
}
